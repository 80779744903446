import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { ToggleSection } from 'components';
import moment from 'moment-timezone';
import styles from './style.module.scss';
import { ProviderNotesReviewHeaderProps } from './ProviderNotesReview.types';
import { CompositionStatusType } from 'apis/medical';
import { joinString } from 'utils/helper';
import SoapNoteHistoryReview from './SoapNoteHistoryReview';
export default function ProviderNotesReviewHeader({
  loading,
  serviceRequest,
  notes,
  appointment,
  patient,
  facility,
}: ProviderNotesReviewHeaderProps) {
  const soapNoteHistories = useMemo(() => {
    return notes?.soapNoteHistory?.filter(
      (history) => history.status.includes('approved') || history.status.includes('rejected')
    );
  }, [notes]);
  return (
    <>
      {!loading && (
        <Grid item xs={12} style={{ marginBottom: '16px' }}>
          <table className={styles.serviceRequestTable}>
            <tbody>
              <tr>
                <td colSpan={2}>
                  <div>Patient Name</div>
                  <div>{joinString([...(patient?.given_names || []), patient?.family_name])}</div>
                </td>
                <td colSpan={2}>
                  <div>Date of Birth</div>
                  <div>{patient?.birth_date ? moment(patient.birth_date).format('MM/DD/YYYY') : ''}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div>ICN</div>
                  <div>{patient?.icn}</div>
                </td>
                <td colSpan={2}>
                  <div>EDIPI</div>
                  <div>{patient?.edipi}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <div>Referral Type</div>
                  <div>{serviceRequest?.condition_type}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div>Referral #</div>
                  <div>{serviceRequest?.referral_number}</div>
                </td>
                <td colSpan={2}>
                  <div>Referral Expiration</div>
                  <div>
                    {serviceRequest?.expiration_date ? moment(serviceRequest.expiration_date).format('MM/DD/YYYY') : ''}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div>Provisional Diagnosis</div>
                  <div>{serviceRequest?.provisional_diagnosis}</div>
                </td>
                <td colSpan={2}>
                  <div>Diagnosis Codes</div>
                  <div>{(serviceRequest?.diagnosis_codes || [])?.map((dc) => dc)?.join(', ')}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div>Session Date</div>
                  <div>{appointment.date ? moment(appointment.date).format('MM/DD/YYYY') : ''}</div>
                </td>
                <td colSpan={2}>
                  <div>Session #</div>
                  <div>
                    {serviceRequest?.quantity
                      ? `${appointment?.sessionNumber || ''} of ${serviceRequest.quantity.value}`
                      : ''}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div>CPT Code</div>
                  <div>{notes?.cptCode}</div>
                </td>
                <td>
                  <div>Duration</div>
                  <div>{appointment?.timeSegment ? `${appointment.timeSegment} minutes` : ''}</div>
                </td>
                <td>
                  <div>Start</div>
                  <div>
                    {appointment?.start ? moment(appointment?.start).tz('America/New_York').format('hh:mma z') : ''}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div>Provider Name</div>
                  <div>
                    {joinString([
                      ...(appointment.practitioner?.name?.[0]?.given || []),
                      appointment.practitioner?.name?.[0]?.family,
                    ])}
                  </div>
                </td>
                <td>
                  <div>Treatment Cadence</div>
                  <div>{serviceRequest?.treatment_cadence}</div>
                </td>
                <td>
                  <div>NPI</div>
                  <div>{appointment.practitioner?.npi}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div>Chief Complaint</div>
                  <div>{serviceRequest?.chief_complaint}</div>
                </td>
                <td colSpan={2}>
                  <div>Body Part(s)</div>
                  <div>{(serviceRequest?.body_parts || [])?.map((bp) => bp.display)?.join(', ')}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <div>VAMC</div>
                  <div>{joinString([facility?.facility_id, facility?.name])}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <div>Internal Facility Notes (private within Zeel)</div>
                  <div>{facility?.internal_note?.text}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <div>External Facility Notes (visible to providers)</div>
                  <div>{facility?.external_note?.text}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <div>Patient History</div>
                  <div>{serviceRequest?.comorbidities}</div>
                </td>
              </tr>
              {serviceRequest?.hasClinicalRationaleForExtension && (
                <tr>
                  <td colSpan={4}>
                    <div>Clinical Rationale For Extension</div>
                    {serviceRequest?.duration_ext_multiple_body_parts && (
                      <div>
                        <div className={styles.extCheckbox}>
                          <input type='checkbox' checked disabled id='duration_ext_multiple_body_parts' />
                          <label htmlFor='duration_ext_multiple_body_parts'>
                            Multiple body parts require treatment as indicated by the provisional diagnosis
                          </label>
                        </div>
                        <div className={styles.extDetails}>
                          <div>Which specific body parts?</div>
                          <div>{serviceRequest?.duration_ext_multiple_body_parts_details}</div>
                        </div>
                      </div>
                    )}
                    {serviceRequest?.duration_ext_regions && (
                      <div>
                        <div className={styles.extCheckbox}>
                          <input type='checkbox' checked disabled id='duration_ext_regions' />
                          <label htmlFor='duration_ext_regions'>
                            Regions above and below the primary treatment area require concurrent treatment to maximize
                            patient function
                          </label>
                        </div>
                        <div className={styles.extDetails}>
                          <div>Which regions and muscles?</div>
                          <div>{serviceRequest?.duration_ext_regions_details}</div>
                        </div>
                      </div>
                    )}
                    {serviceRequest?.duration_ext_symptoms && (
                      <div>
                        <div className={styles.extCheckbox}>
                          <input type='checkbox' checked disabled id='duration_ext_symptoms' />
                          <label htmlFor='duration_ext_symptoms'>
                            Due to the complex nature of the patient&apos;s symptoms, extra time is required to properly
                            address the issues
                          </label>
                        </div>
                        <div className={styles.extDetails}>
                          <div>Patient&apos;s symptoms that require them to need more time...</div>
                          <div>{serviceRequest?.duration_ext_symptoms_details}</div>
                        </div>
                      </div>
                    )}
                    {serviceRequest?.duration_ext_other && (
                      <div>
                        <div className={styles.extCheckbox}>
                          <input type='checkbox' checked disabled id='duration_ext_other' />
                          <label htmlFor='duration_ext_other'>Other</label>
                        </div>
                        <div className={styles.extDetails}>
                          <div>Details</div>
                          <div>{serviceRequest?.duration_ext_other_details}</div>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Grid>
      )}
      {notes?.rejectionNote && (
        <Grid item xs={12} style={{ marginBottom: '16px' }}>
          <ToggleSection title='Prior Review Instruction' disabled={loading}>
            <p className={styles.rejectionNote}>{notes?.rejectionNote}</p>
          </ToggleSection>
        </Grid>
      )}
      <Grid item xs={12}>
        <ToggleSection title='History'>
          <SoapNoteHistoryReview histories={soapNoteHistories || []} loading={loading} />
        </ToggleSection>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: '16px' }}>
        <p className={styles.title}>
          {[CompositionStatusType.Rejected, CompositionStatusType.Submitted].includes(
            notes?.noteStatus as CompositionStatusType
          ) && 'Re-'}
          Review SOAP Notes
        </p>
        {serviceRequest?.quantity && (
          <p className={styles.providerTopInfo}>
            Session {appointment?.sessionNumber || ''}/{serviceRequest.quantity.value} in referral
          </p>
        )}
        {appointment?.practitioner && (
          <p className={styles.providerTopInfo}>
            {joinString([
              'Submitted by',
              ...appointment.practitioner.name[0].given,
              appointment.practitioner.name[0].family,
              appointment.practitioner.npi ? `(NPI ${appointment.practitioner.npi})` : '',
            ])}
          </p>
        )}
        {patient && (
          <p className={styles.providerTopInfo}>
            {joinString([
              'For patient',
              ...(patient?.given_names || []),
              patient?.family_name,
              appointment?.date ? `on ${moment(appointment.date).format('LL')}` : '',
            ])}
          </p>
        )}
      </Grid>
    </>
  );
}
