import React, { useEffect } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { DocumentApi } from 'apis';
import { DocumentType } from 'apis/medical';
import { useState } from 'utils/hooks';
import { downloadFile } from 'utils/helper';
import { FieldTitle, Button, Icon } from 'components';
import LinearProgress from '@mui/material/LinearProgress';
import NoteAdd from '@mui/icons-material/NoteAdd';
import styles from './style.module.scss';

export type FilesContainerProps = {
  title?: string;
  showTitle?: boolean;
  label?: string;
  condensed?: boolean;
  actionText?: string;
  onActionClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
};

export type FilesContainerInnerProps = FilesContainerProps & {
  loading?: boolean;
  files: Array<DocumentType>;
};

export default function FilesContainer({
  loading,
  files = [],
  showTitle = true,
  title = 'Associated Files',
  label,
  condensed,
  actionText = 'Upload Files',
  onActionClick,
}: FilesContainerInnerProps) {
  const [documents, setDocuments] = useState<any>([]);

  useEffect(() => {
    setDocuments(files);
  }, [JSON.stringify(files)]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDownloadFile = async (documentId: number) => {
    // set document downloading
    setDocuments(
      documents.map((d: any) => {
        if (d.id === documentId) {
          d.downloading = true;
          d.errored = false;
        }
        return d;
      })
    );

    let hasErrored = false;
    try {
      const doc: any = documents?.find((d: any) => d.id === documentId);
      if (!doc) return;

      const responseBlob = await DocumentApi.downloadDocument(doc?.id);
      const url = window.URL.createObjectURL(responseBlob);
      downloadFile(url, `${doc?.meta?.fileName || `file-${new Date().getTime()}`}`);
    } catch (error) {
      hasErrored = true;
    }

    // reset document flags
    setDocuments(
      documents.map((d: any) => {
        if (d.id === documentId) {
          d.downloading = false;
          if (hasErrored) {
            d.errored = true;
          }
        }
        return d;
      })
    );
  };

  const rightContent = onActionClick ? (
    <Button startIcon={<NoteAdd />} variant='outlined' color='primary' size='small' onClick={onActionClick}>
      {actionText}
    </Button>
  ) : null;
  return (
    <div>
      {showTitle && (
        <FieldTitle icon='notes' margin rightContent={rightContent}>
          {title}
        </FieldTitle>
      )}
      {label && <p className={styles.label}>{label}</p>}
      <div className={cn({ [styles.shortFilesContainer]: condensed, [styles.filesContainer]: !condensed })}>
        {loading && (
          <div className={styles.loader}>
            <LinearProgress />
          </div>
        )}
        {!loading && !documents?.length && <p className={styles.noFiles}>No files</p>}
        {!loading &&
          documents?.map((file: any) => {
            const { id, description, meta, displayName } = file;
            const { fileName, mimeType, created } = meta;
            let fileAsset = 'txt';
            if (mimeType === 'application/pdf') fileAsset = 'pdf';
            if (mimeType === 'image/jpeg') fileAsset = 'jpg';

            return (
              <div
                title={description}
                key={id}
                className={cn(styles.file, {
                  [styles['file--downloading']]: file.downloading,
                  [styles['file--errored']]: file.errored,
                })}
                onClick={() => handleDownloadFile(id)}>
                <Icon name={fileAsset} size={24} />
                <div className={styles.infoContainer}>
                  <div className={styles.fileInfo}>
                    <p>{displayName || fileName}</p>
                    {condensed && <p>{created ? moment(created).format('LLL') : ''}</p>}
                  </div>
                  <p className={styles.description}>{description}</p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
