import api from 'apis';
import orderBy from 'lodash/orderBy';
import { CancelToken } from 'axios';
// import { mockFhirServiceRequest, mockFhirServiceRequests, mockReferrals, mockAppointments } from './mock';
import {
  transformApiServiceRequests,
  transformApiPatients,
  transformApiFacilities,
  transformApiComments,
  transformApiSOAPNotes,
  decodeApiSOAPNotes,
  transformApiAppointments,
  generateBookingData,
} from './helper';
import { RequestType } from 'apis/zref';

/**
 * Constants related to medical services (can be used here or across the app)
 */
export const constants: any = {
  formTemplates: {
    VA_REQUEST_FOR_ADDITIONAL_SERVICES: 'abf29107-a16a-47c3-90e5-b6f1195f0f5e',
  },
  booking: {
    corporateEntityId: 3,
    businessLineId: 1,
  },
};

/**
 * Fetch all facilities.
 * Refer to: https://ng.dev.zeel.com/zref/v1/ui/#/
 */
export const fetchFacilities = async (params?: { all?: boolean }): Promise<Array<FacilityType>> => {
  // const facilities = await api.get({
  //   path: '/zref/v1/facilities',
  //   auth: true,
  // });
  // const sortedFacilities = orderBy(facilities, ['name'],['asc']);
  // return sortedFacilities;

  const { all } = params || {};

  const facilities = await api.get({
    path: '/medical_massage/v1/location',
    auth: true,
    data: {
      params: {
        'organization.partof.name': 'Veterans Administration',
        _count: 10000,
        ...(all ? {} : { status: 'active' }),
      },
    },
  });

  const transformedFacilities = transformApiFacilities(facilities);
  const sortedFacilities = orderBy(transformedFacilities, ['name'], ['asc']);
  return sortedFacilities;
};

export const fetchFacility = async (facilityId: string): Promise<FacilityType> => {
  const facilities = await api.get({
    path: '/medical_massage/v1/location',
    auth: true,
    data: {
      params: {
        identifier: `http://www.zeel.com/fhir/va/facility-id|${facilityId}`,
      },
    },
  });

  return transformApiFacilities(facilities)[0];
};

export const fetchFacilityById = async (facilityId: string): Promise<FacilityType> => {
  const facility = await api.get({
    path: `/medical_massage/v1/location/${facilityId}`,
    auth: true,
  });

  return transformApiFacilities([facility])[0];
};

/**
 * Fetch all service requests previously ingested from zref.
 * Refer to: https://ng.dev.zeel.com/medical_massage/v1/ui/#
 */
export const fetchServiceRequests = async (
  params: any = {},
  cancelToken?: CancelToken
): Promise<Array<ServiceRequestType>> => {
  const { count, offset, sort, filters } = params;
  const queryParams: any = {
    _count: count,
    _offset: offset,
  };

  if (sort) {
    queryParams['_sort'] = sort;
  }

  // Adding non-null filters
  Object.keys(filters || {}).forEach((filterKey) => {
    if (filters[filterKey]) queryParams[filterKey] = filters[filterKey];
  });

  const response = await api.get({
    path: '/medical_massage/v1/service-requests',
    auth: true,
    data: {
      params: queryParams,
    },
    cancelToken,
  });

  const transformedServiceRequests = transformApiServiceRequests(response);
  return transformedServiceRequests;
};

/**
 * Fetch a service request by id.
 * Refer to: https://ng.dev.zeel.com/medical_massage/v1/ui/#
 */
export const fetchServiceRequest = async (
  serviceRequestId: string,
  cancelToken?: CancelToken
): Promise<ServiceRequestType> => {
  const response = await api.get({
    path: `/medical_massage/v1/service-requests/${serviceRequestId}`,
    auth: true,
    cancelToken,
  });
  const transformedServiceRequest = transformApiServiceRequests([response])?.[0];
  return transformedServiceRequest;
};

/**
 * Update a service request by id.
 * Refer to: https://ng.dev.zeel.com/medical_massage/v1/ui/#
 */
export const updateServiceRequest = async (serviceRequestId: string, data: any): Promise<any> => {
  const response = await api.patch({
    path: `/medical_massage/v1/service-requests/${serviceRequestId}`,
    auth: true,
    data,
  });
  return response;
};

/**
 * Fetch a service request by referral id.
 * Refer to: https://ng.dev.zeel.com/medical_massage/v1/ui/#
 */
export const fetchServiceRequestByReferralNumber = async (referralNumber: string): Promise<Array<any>> => {
  const response = await api.get({
    path: `/medical_massage/v1/service-requests`,
    auth: true,
    data: {
      params: {
        identifier: `http://www.zeel.com/fhir/va/referral-number | ${referralNumber}`,
      },
    },
  });
  return response;
};

/**
 * Create consent object for a service-request.
 * Refer to: https://ng.dev.zeel.com/medical_massage/v1/ui/#
 */
export const createConsent = async (data: {
  patientId: string;
  consentType: string;
  serviceRequestId: string;
  url: string;
  status?: string;
}) => {
  const { patientId, consentType, serviceRequestId, url, status = 'active' } = data;

  const response = await api.post({
    path: `/medical_massage/v1/service-requests/${serviceRequestId}/consent`,
    auth: true,
    data: {
      type: consentType,
      patient_id: patientId,
      source: url,
      status,
    },
  });

  return response;
};

/**
 * Update a service request by id.
 * Refer to: https://ng.dev.zeel.com/medical_massage/v1/ui/#
 */
export const addServiceRequestFiles = async (
  serviceRequestId: string,
  files: Array<{
    url: string;
    description?: string;
    type?: string;
  }>
) => {
  const response = await api.post({
    path: `/medical_massage/v1/service-requests/${serviceRequestId}/supporting-info`,
    auth: true,
    data: (files || []).map((file) => {
      const { url, description, type } = file;
      return {
        url,
        description,
        type,
      };
    }),
  });

  return response;
};

export const revokeReferral = async (serviceRequestId: string, data: { reason?: string } = {}): Promise<any> => {
  const { reason } = data;
  const response = await api.patch({
    path: `/medical_massage/v1/service-requests/${serviceRequestId}`,
    auth: true,
    data: {
      status: 'revoked',
      revocation_reason: reason,
      // reviewed_by: 'Joseph Torre',
    },
  });
  return response;
};

/**
 * Fetch all patients.
 * Refer to: https://ng.dev.zeel.com/medical_massage/v1/ui/#
 */
export const fetchPatients = async (params: any = {}, cancelToken?: CancelToken): Promise<Array<PatientType>> => {
  const { count, offset, sort, filters } = params;
  const queryParams: any = {
    _count: count,
    _offset: offset,
  };

  if (sort) {
    queryParams['_sort'] = sort;
  }

  // Adding non-null filters
  Object.keys(filters || {}).forEach((filterKey) => {
    if (filters[filterKey]) queryParams[filterKey] = filters[filterKey];
  });

  const patients = await api.get({
    path: '/medical_massage/v1/patient',
    auth: true,
    data: {
      params: queryParams,
    },
    cancelToken,
  });

  const transformedPatients = transformApiPatients(patients);
  return transformedPatients;
};

/**
 * Fetch patient by id.
 * Refer to: https://ng.dev.zeel.com/medical_massage/v1/ui/#
 */
export const fetchPatient = async (patientId: string, cancelToken?: CancelToken): Promise<PatientType> => {
  const response = await api.get({
    path: `/medical_massage/v1/patient/${patientId}`,
    auth: true,
    cancelToken,
  });
  const patient = response?.patient;
  const transformedPatient = transformApiPatients([patient])?.[0];
  return transformedPatient;
};

export const fetchPatientByIcn = async (icn: string): Promise<PatientType> => {
  const response = await api.get({
    path: `/medical_massage/v1/patient`,
    auth: true,
    data: {
      params: {
        identifier: `http://www.zeel.com/fhir/va/icn|${icn}`,
      },
    },
  });

  const transformedPatient = transformApiPatients(response || [])?.[0];
  return transformedPatient;
};

/**
 * Update patient
 * Refer to: https://ng.dev.zeel.com/medical_massage/v1/ui/#
 */
export const updatePatient = async (patientId: string, data: any): Promise<any> => {
  const response = await api.patch({
    path: `/medical_massage/v1/patient/${patientId}`,
    auth: true,
    data,
  });
  return response;
};

export const updateFacility = async (facilityId: string, data: any): Promise<any> => {
  const response = await api.patch({
    path: `/medical_massage/v1/location/${facilityId}`,
    auth: true,
    data,
  });
  return response;
};

export const updateAppointment = async (appointmentId: string, data: any): Promise<any> => {
  const response = await api.patch({
    path: `/medical_massage/v1/appointment/${appointmentId}`,
    auth: true,
    data,
  });
  return response;
};

export const updateComposition = async (compositionId: string, data: any): Promise<any> => {
  const response = await api.patch({
    path: `/medical_massage/v1/composition/${compositionId}`,
    auth: true,
    data,
  });
  return response;
};

/**
 * Update caregiver
 * Refer to: https://ng.dev.zeel.com/medical_massage/v1/ui/#
 */
export const updateCaregiver = async (caregiverId: string, data: any): Promise<any> => {
  const response = await api.patch({
    path: `/medical_massage/v1/caregiver/${caregiverId}`,
    auth: true,
    data,
  });
  return response;
};

export const fetchAppointment = async (ogAppointmentId: string): Promise<AppointmentType> => {
  const response = await api.get({
    path: `/medical_massage/v1/appointment`,
    auth: true,
    data: {
      params: {
        identifier: `http://www.zeel.com/fhir/appointment_id|${ogAppointmentId}`,
      },
    },
  });
  return transformApiAppointments(response || [])[0];
};

/**
 * Fetch all appointments associated with a service request.
 */
export const fetchAppointmentsForServiceRequest = async (serviceRequestId: string): Promise<AppointmentType[]> => {
  const appointments = await api.get({
    path: '/medical_massage/v1/appointment',
    auth: true,
    data: {
      params: {
        'based-on': `ServiceRequest/${serviceRequestId}`,
      },
    },
  });

  return transformApiAppointments(appointments);
};

/**
 * Fetch all appointments taken by a practitioner.
 */
export const fetchAppointmentsOfPractitioner = async (practitionerId: string): Promise<AppointmentType[]> => {
  const appointments = await api.get({
    path: '/medical_massage/v1/appointment',
    auth: true,
    data: {
      params: {
        practitioner: `Practitioner/${practitionerId}`,
        _count: 100,
      },
    },
  });

  return transformApiAppointments(appointments);
};

/**
 * Fetch all appointments from OG
 */
export const fetchOGAppointments = async (
  { page = 1, filters = {}, order_by = [], order = [] }: any,
  cancelToken?: CancelToken
): Promise<{ appointments: Array<any>; total: number }> => {
  const response = await api.get({
    path: `/appointments/va_medical_reporting`,
    version: 'og-cms',
    auth: true,
    data: {
      only_copyable_appointments: filters?.onlyCopyable,
      page,
      name: filters?.name,
      id: filters?.id,
      stages: filters?.stages,
      order_by: order_by[0],
      order: order[0],
      ...(filters?.noSoapNotesSubmitted ? { soap_notes_added: false } : {}),
    },
    cancelToken,
  });
  return {
    appointments: transformApiAppointments(response?.response?.appointments),
    total: response?.response?.total_appointments,
  };
};

/**
 * Fetch all appointments from OG
 */
export const fetchOGAppointment = async (appointmentId: string): Promise<AppointmentType> => {
  const response = await api.get({
    path: `/appointments/va_medical_reporting`,
    version: 'og-cms',
    auth: true,
    data: {
      id: appointmentId,
    },
  });
  return transformApiAppointments(response?.response?.appointments)?.[0];
};

/**
 * Fetch all appointments that are copyable from OG
 */
export const fetchOGAppointmentsToCopy = async (
  { page = 1, filters = {} }: any,
  cancelToken?: CancelToken
): Promise<{ appointments: Array<any>; total: number }> => {
  const response = await api.get({
    path: `/appointments/va_medical_reporting`,
    version: 'og-cms',
    auth: true,
    data: {
      only_copyable_appointments: true,
      page,
      name: filters?.name,
    },
    cancelToken,
  });
  return {
    appointments: transformApiAppointments(response?.response?.appointments),
    total: response?.response?.total_appointments,
  };
};

/**
 * Deprecated
 */
export const fetchOGAppointmentsFlaggedForReview = async (
  cancelToken?: CancelToken
): Promise<{ appointments: Array<any>; total: number }> => {
  const response = await api.get({
    path: `/appointments/va_medical_reporting`,
    version: 'og-cms',
    auth: true,
    data: {
      only_priority_notes: true,
      not_reviewed_appointments: true,
      show_all: true,
      // page,
      // name: filters?.name,
    },
    cancelToken,
  });
  return {
    appointments: transformApiAppointments(response?.response?.appointments),
    total: response?.response?.total_appointments,
  };
};

export const fetchOGAppointmentComments = async (appointmentId: string): Promise<any> => {
  const response = await api.get({
    path: `/appointments/${appointmentId}/get`,
    version: 'og-cms',
    auth: true,
    data: {
      object: {
        id: appointmentId,
      },
      'with[]': 'sticky_notes.member',
    },
  });

  return transformApiComments(response?.response?.sticky_notes || []);
};

export const addComment = async ({ appointmentId, text }: { appointmentId: string; text: string }): Promise<any> => {
  const response = await api.get({
    path: `/appointments/${appointmentId}/add_sticky_note`,
    version: 'og-cms',
    auth: true,
    data: {
      object: {
        id: appointmentId,
        _note: text,
      },
      'with[]': 'sticky_notes.member',
    },
  });

  return transformApiComments(response?.response?.sticky_notes || []);
};

export const updateComment = async ({
  appointmentId,
  commentId,
  text,
}: {
  appointmentId: string;
  commentId: string;
  text: string;
}): Promise<any> => {
  const response = await api.get({
    path: `/appointments/${appointmentId}/update_sticky_note`,
    version: 'og-cms',
    auth: true,
    data: {
      object: {
        id: appointmentId,
        _sticky_note_id: commentId,
        _note: text,
      },
      'with[]': 'sticky_notes.member',
    },
  });

  return transformApiComments(response?.response?.sticky_notes || []);
};

export const deleteComment = async ({
  appointmentId,
  commentId,
}: {
  appointmentId: string;
  commentId: string;
}): Promise<any> => {
  const response = await api.get({
    path: `/appointments/${appointmentId}/delete_sticky_note`,
    version: 'og-cms',
    auth: true,
    data: {
      object: {
        id: appointmentId,
        _sticky_note_id: commentId,
      },
    },
  });

  return response;
};

export const fetchProcedure = async (procedureId: string): Promise<any> => {
  const response = await api.get({
    path: `/medical_massage/v1/procedure/${procedureId}`,
    auth: true,
  });
  return response;
};

export const fetchProcedureByServiceRequestId = async (serviceRequestId: string): Promise<any> => {
  const response = await api.get({
    path: `/medical_massage/v1/procedure`,
    auth: true,
    data: {
      params: {
        'based-on': serviceRequestId,
      },
    },
  });
  return response?.[0];
};

export const fetchProcedureNotes = async (procedureId: string): Promise<any> => {
  const response = await api.get({
    path: `/medical_massage/v1/procedure/${procedureId}/compositions`,
    auth: true,
  });
  return transformApiSOAPNotes(response);
};

export const fetchApprovedAndAmendedSoapNotes = async (referralNumber: string) => {
  const response = await api.get({
    path: `/medical_massage/v1/composition`,
    data: {
      params: {
        zeel_status: ['approved', 'amended'],
        'encounter.based-on.identifier': referralNumber,
      },
    },
    auth: true,
  });
  return transformApiSOAPNotes(response);
};

export const priorityReviewProcedure = async (procedureId: string, reviewNotes: string) => {
  const response = await api.post({
    path: `/medical_massage/v1/composition`,
    auth: true,
    data: {
      notes: [
        {
          text: reviewNotes || '',
          title: 'doctor note',
        },
      ],
      procedure_id: procedureId,
      status: CompositionStatusType.Final,
      title: CompositionTitleType.MEDICAL_REVIEW_NOTES,
    },
  });
  return response;
};

export const fetchSoapNotesToReview = async (cancelToken?: CancelToken) => {
  const response = await api.get({
    path: `/medical_massage/v1/composition`,
    auth: true,
    data: {
      params: {
        status: ['preliminary', 'submitted'],
        _count: 10000,
      },
    },
    cancelToken,
  });
  return decodeApiSOAPNotes(response);
};

export const fetchSoapNote = async (noteId: string | number) => {
  const response = await api.get({
    path: `/medical_massage/v1/composition/${noteId}`,
    auth: true,
  });
  return decodeApiSOAPNotes([response])[0];
};

export const fetchPreviousVersionSoapNote = async (noteId: string | number) => {
  const response = await api.get({
    path: `/medical_massage/v1/composition/${noteId}/compare-versions`,
    auth: true,
  });
  return {
    ...response,
    current_version: decodeApiSOAPNotes([response.current_version])[0],
    previous_version: decodeApiSOAPNotes([response.previous_version])[0],
  };
};

export const fetchAllSoapNote = async (serviceRequestId: string | number): Promise<CompositionType[]> => {
  const response = await api.get({
    path: `/medical_massage/v1/composition?params[subject:Procedure.based-on]=ServiceRequest/${serviceRequestId}`,
    auth: true,
  });
  return transformApiSOAPNotes(decodeApiSOAPNotes(response));
};

export const fetchSoapNotesWithOgAppointmentId = async (
  ogAppointmentId: string
): Promise<CompositionType | undefined> => {
  const response = await api.get({
    path: `/medical_massage/v1/composition?params[subject:Procedure.identifier]=${ogAppointmentId}&params[title]=SOAP Notes`,
    auth: true,
  });

  return response?.length > 0 ? transformApiSOAPNotes(decodeApiSOAPNotes(response))?.[0] : undefined;
};

export const updateSoapNote = async (
  noteId: string,
  updatedNote: {
    notes?: Array<NoteType>;
    reviewed_by?: string;
    procedure_id?: string;
    status?: string;
    title?: string;
    rejection_note?: string;
    document_url?: string;
  }
) => {
  const response = await api.patch({
    path: `/medical_massage/v1/composition/${noteId}`,
    auth: true,
    data: updatedNote,
  });
  return response;
};

export const setSoapNotesAsUploadedToHSRM = async (compositionIds: string[]): Promise<CompositionType[]> => {
  const response = await api.post({
    path: '/medical_massage/v1/composition/_mark_uploaded_hsrm',
    auth: true,
    data: {
      composition_ids: compositionIds,
    },
  });
  return response;
};

export const createSoapNote = async (note: {
  notes?: Array<{ title: string; text: string }>;
  procedure_id: string;
  status?: string;
  title?: string;
  date: string;
  details: string;
  memberId: string;
}) => {
  const { notes, procedure_id, status, title, date, details, memberId } = note || {};
  const createResponse = await api.post({
    path: `/medical_massage/v1/composition`,
    auth: true,
    data: {
      notes,
      procedure_id,
      status,
      title,
      zcc_entry: {
        date_authored: date,
        member_id: memberId,
        details,
      },
    },
  });
  const response = await updateSoapNote(createResponse.id, {
    status: 'submitted',
  });
  return response;
};

export const setOGAppointmentAsCopied = async (appointmentId: string): Promise<any> => {
  const response = await api.get({
    path: `/appointments/copied_into_hsrm`,
    version: 'og-cms',
    auth: true,
    data: {
      appointment_id: appointmentId,
    },
  });
  return response;
};

export const setOGAppointmentNotesAsReviewed = async (appointmentId: string): Promise<any> => {
  const response = await api.get({
    path: `/appointments/note_reviewed`,
    version: 'og-cms',
    auth: true,
    data: {
      appointment_id: appointmentId,
    },
  });
  return response;
};

export const fetchOGBookingData = async (
  memberId: string,
  serviceRequestId: string
): Promise<{ booking: OGBookingData }> => {
  const { response } = await api.post({
    path: `/members/${memberId}/booking_new`,
    version: 'og-cms',
    auth: true,
    data: {
      service_request_id: serviceRequestId,
      corporate_entity_id: constants.booking.corporateEntityId,
      business_line_id: constants.booking.businessLineId,
    },
    throwOGError: true,
  });
  return response;
};

export const fetchOGBookingTimes = async (memberId: string, bookingData: any): Promise<any> => {
  const apiBookingData = generateBookingData(bookingData);
  const { response } = await api.post({
    path: `/members/${memberId}/booking_times`,
    version: 'og-cms',
    auth: true,
    data: {
      ...apiBookingData,
      _data: '',
    },
    throwOGError: true,
  });
  return response;
};

export const fetchOGBookingAddresses = async (memberId: string): Promise<{ editable_addresses: OGAddressType[] }> => {
  const response = await api.post({
    path: `/member/details/get/${memberId}`,
    version: 'og-cms-ctrl',
    auth: true,
  });
  return response;
};

export const createOrUpdateOGBookingAddress = async (memberId: string, address: OGAddressType): Promise<any> => {
  const { response } = await api.post({
    path: `/member/details/save/${memberId}/address`,
    version: 'og-cms-ctrl',
    data: address,
    auth: true,
    throwOGError: true,
  });
  return response;
};

export const removeOGBookingAddress = async (memberId: string, addressId: string): Promise<any> => {
  await api.post({
    path: `/member/details/remove/${memberId}/address/${addressId}`,
    version: 'og-cms-ctrl',
    auth: true,
    throwOGError: true,
  });
};

export const createOGBooking = async (memberId: string, bookingData: any): Promise<any> => {
  const apiBookingData = generateBookingData({
    ...bookingData,
    corporateEntityId: constants.booking.corporateEntityId,
    businessLineId: constants.booking.businessLineId,
  });
  const response = await api.post({
    path: `/members/${memberId}/booking_create`,
    version: 'og-cms',
    auth: true,
    data: apiBookingData,
    throwOGError: true,
  });
  return response;
};

// export const uploadDocument: any = async (file: File, meta: any = null): Promise<string> => {
//   const formData = new FormData();
//   formData.append('document', file);
//   if (meta) formData.append('meta', meta);

//   const response = await api.post({
//     path: '/document/v1',
//     auth: true,
//     headers: {
//       'Content-Type': 'multipart/form-data'
//     },
//     data: formData
//   });

//   return response?.location;
// }

// TYPES

export interface OGAddressType {
  id: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  table_count?: number;
  floor?: number;
  parking?: string;
  client_id: string;
  zip: string;
  hastable: number;
  name: string;
}

export type OGBookingDate = {
  date: string;
  end_time_id: string;
  hours: Array<{
    am: boolean;
    date: string;
    end_am: boolean;
    end_full_hour: number;
    end_hour: number;
    end_label: string;
    full_hour: number;
    hour: number;
    label: string;
    minutes: Array<{
      date: string;
      end_hour_am: boolean;
      end_hour_full_hour: number;
      end_hour_hour: number;
      end_hour_label: string;
      end_label: string;
      end_minute: number;
      id: number;
      label: string;
      minute: number;
      selected: boolean;
    }>;
  }>;
  label: string;
  label_long: string;
  month: string;
  of_month: number;
  of_week: number;
  selected: boolean;
  start_time_id: number;
  week_no: number;
  year: number;
};

export type OGBookingData = {
  appointments: Array<{
    length: number;
    therapist_gender_requested?: string;
    customer: {
      person_id: number;
      guest: boolean;
    };
    editable_length: boolean;
    editable_service: boolean;
    editable_therapist: boolean;
    notes?: any;
    service: {
      id: number;
    };
  }>;
  back_to_back?: boolean;
  business_line_id: string;
  card_id?: any;
  category: string;
  client: {
    id: number;
  };
  corporate_entity_id: number;
  customer: {
    id: number;
  };
  editable_category: boolean;
  location: {
    client_id: number;
    editable_fields: Array<any>;
    id: number;
  };
  office_based_referral: boolean;
  request_time: {
    start?: number;
    end?: number;
  };
  service_request_id: number;
  unsigned_agreements: Array<any>;
  provider: {
    employers: string;
    excerpt: string;
    fname: string;
    id: number;
    license_number?: string;
    lname: string;
    member_entourage_level?: string;
    mobile: string;
    profile_pic_regular_path?: string;
    profile_pic_small_path?: string;
    profile_pic?: string;
    memberships?: string;
    referral_reward: number;
    sex: string;
    specialty: string;
    specialty_school: string;
  };
};

export interface AddressType {
  id?: string;
  city?: string;
  line?: Array<string>;
  postal_code?: string;
  state?: string;
  country?: string;
}

export interface CaregiverType {
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  relationship?: string;
}

export interface MetaTagType {
  code?: string;
  display?: string;
  system?: string;
}

export interface PatientType {
  id: string;
  active?: boolean;
  birth_date: string;
  caregiver?: CaregiverType;
  gender?: string;
  referral_number?: string;
  edipi?: string;
  icn: string;
  member_id?: string;
  ahf_member_id?: string;
  hold_status?: PatientHoldKeys;
  hold_status_reason?: string;
  hold_status_date?: string;
  hold_created_date?: string;
  given_names: Array<string>;
  family_name: string;
  address: Array<AddressType>;
  test?: boolean;
  email?: string;
  phone?: string;
  phoneMobile?: string;
  phoneWork?: string;
  contactPreference?: string;
  ssn?: string;
  service_requests?: Array<ServiceRequestType>;
  removed: boolean | null;
  referring_organization?: string;
}

export enum PatientHoldStatus {
  scheduling = 'Scheduling Hold',
  medical = 'Medical Hold',
  trust_and_safety = 'Patient Review Hold',
}

export type PatientHoldKeys = keyof typeof PatientHoldStatus;

export interface NoteType {
  title: string;
  text: string;
}

export type DocumentType = {
  id: string;
  url?: string;
  displayName?: string;
  description?: string;
  rightDescription?: string;
  type?: string;
  downloading?: boolean;
  errored?: boolean;
  meta?: {
    fileName: string;
    fileSize: number;
    mimeType: string;
    tags?: any;
    created?: string;
  };
};

export interface ApiPatientType {
  id: string;
  active: boolean;
  resource_type: string;
  address: Array<AddressType>;
  birth_date?: string;
  caregivers?: Array<{
    active?: boolean;
    id: string;
    meta: {
      last_updated: string;
      source: string;
      version_id: string;
    };
    name: Array<{
      family: string;
      given: Array<string>;
    }>;
    patient?: {
      reference: string;
      type: string;
    };
    relationship: Array<any>; // need to define further
    resource_type: string;
    telecom: Array<{ system: string; value: any; use?: string; rank?: number }>;
  }>;
  communication?: any;
  gender?: string;
  identifier?: Array<{
    system: string;
    value: any;
  }>;
  meta?: {
    tag?: Array<MetaTagType>;
  };
  name: Array<{
    given: Array<string>;
    family: string;
  }>;
  telecom?: Array<{
    system: string;
    value: any;
    use?: string;
    rank?: number;
  }>;
  service_requests?: Array<ApiServiceRequestType>;
  removed_from_network?: string | null;
  patient_ssn?: string;
  hold_status?: 'scheduling' | 'medical' | 'trust_and_safety';
  hold_status_reason?: string;
  hold_status_date?: string;
  hold_created_date?: string;
  referring_organization?: string;
}

export interface QuantityType {
  value: number;
  available: number;
  completed?: number;
  planned?: number;
}
export interface ApiServiceRequestType {
  id: string;
  assigned_provider?: string;
  authored_on: string;
  code?: {
    coding?: Array<MetaTagType>;
  };
  consent?: Array<{ type: string; url?: string; signed_on?: string }>;
  identifier?: Array<{
    system: string;
    value: any;
  }>;
  meta?: {
    tag?: Array<MetaTagType>;
  };
  insurance: Array<InsuranceType>;
  intent: string;
  note: Array<{ text: string; time: string }>;
  occurrence_period: {
    start?: string;
    end?: string;
  };
  occurrencePeriod?: {
    start?: string;
    end?: string;
  };
  quantity: QuantityType;
  quantityQuantity: QuantityType;
  office_based_referral?: boolean;
  service_duration?: number;
  status: string;
  subject: {
    reference: string;
    type: string;
  };
  reason_reference?: Array<{
    id?: string;
    description?: string;
    url?: string;
    type?: string;
  }>;
  revocation_reason?: string;
  revocation_date?: string;
  supporting_info?: Array<{
    description?: string;
    url?: string;
    type?: string;
  }>;
  resource_type: string;
  condition_type?: string;
  conditions?: Array<{
    body_site?: Array<{
      coding?: Array<{
        code?: string;
        display: string;
        system?: string;
      }>;
    }>;
    chief_complaint?: string;
    code?: {
      coding: Array<{
        code: string;
        system: string;
      }>;
    };
    comorbidities?: string;
    extensions?: Array<any>;
    id?: string;
    identifier?: Array<any>;
    meta?: any;
    provisional_diagnosis?: string;
    recorded_date?: string;
    resource_type?: string;
    subject?: {
      reference: string;
      type: string;
    };
  }>;
  requested_location: Array<{ address: AddressType }>;
  va_facility_id?: string;
  treatment_cadence?: 'weekly' | 'biweekly' | 'monthly';
  duration_ext_multiple_body_parts?: boolean;
  duration_ext_multiple_body_parts_details?: string;
  duration_ext_regions?: boolean;
  duration_ext_regions_details?: string;
  duration_ext_symptoms?: boolean;
  duration_ext_symptoms_details?: string;
  duration_ext_other?: boolean;
  duration_ext_other_details?: string;
  referring_organization?: string;
}

export enum ServiceRequestStatusType {
  Draft = 'draft',
  Active = 'active',
  Completed = 'completed',
  Revoked = 'revoked',
}

export interface InsuranceType {
  priority: string;
  insurer?: string;
  subscriber_id?: string;
}

export interface ServiceRequestType {
  id: string;
  assigned_provider?: string;
  patient_id: string;
  chief_complaint?: string;
  comorbidities?: string;
  provisional_diagnosis?: string;
  body_parts?: Array<{
    code?: string;
    display: string;
    system?: string;
  }>;
  diagnosis_codes?: Array<string>;
  issue_date: string; // YYYY-MM-DD
  expiration_date: string; // YYYY-MM-DD
  consent?: Array<{ type: string; url?: string; signed_on?: string }>;
  note: Array<{ text: string; time: string }>;
  status: ServiceRequestStatusType;
  insurer?: string;
  referral_number?: string;
  quantity: QuantityType;
  quantityQuantity: QuantityType;
  duration: number;
  created_at: string;
  referral_document: { id?: string; description?: string; url?: string; type?: string };
  additional_documents?: Array<DocumentType>;
  office_based_referral?: boolean;
  test?: boolean;
  revocation_reason?: string;
  revocation_date?: string;
  // additional optional properties (for convenience)
  request?: RequestType;
  hasExtensionRequest: boolean;
  va_facility_id?: string;
  requested_location: Array<{ address: AddressType }>;
  condition_type?: string;
  treatment_cadence?: 'weekly' | 'biweekly' | 'monthly';
  duration_ext_multiple_body_parts?: boolean;
  duration_ext_multiple_body_parts_details?: string;
  duration_ext_regions?: boolean;
  duration_ext_regions_details?: string;
  duration_ext_symptoms?: boolean;
  duration_ext_symptoms_details?: string;
  duration_ext_other?: boolean;
  duration_ext_other_details?: string;
  hasClinicalRationaleForExtension?: boolean;
  referring_organization?: string;
  insurance?: Array<InsuranceType>;
}

export interface ApiFacilityType {
  address?: AddressType;
  id: string;
  identifier?: Array<{
    system: string;
    value?: string | number;
  }>;
  managing_organization?: {
    reference?: string;
    type?: string;
  };
  meta?: {
    last_updated?: string;
    source?: string;
    version?: string;
  };
  mode?: string;
  name: string;
  position?: {
    latitude: string;
    longitude: string;
  };
  resource_type: string;
  status: string;
  telecom?: Array<{
    system: string;
    value?: string | number;
    use?: string;
    rank?: number;
  }>;
  external_note?: {
    pcc_agent: string;
    text: string;
    timestamp: string;
  };
  internal_note?: {
    pcc_agent: string;
    text: string;
    timestamp: string;
  };
  ready_for_orientation?: boolean;
  massage_therapy_new?: boolean;
  contact_information?: string;
  referral_method?: 'tpa' | 'ppms' | 'word_of_mouth' | 'other';
}

export interface FacilityType {
  id: string;
  facility_id: string;
  name: string;
  phone?: string;
  line_1?: string;
  line_2?: string;
  locality?: string;
  postal?: string;
  region?: string;
  created_at?: string;
  updated_at?: string;
  address?: AddressType;
  status?: string;
  external_note?: {
    pcc_agent: string;
    text: string;
    timestamp: string;
  };
  internal_note?: {
    pcc_agent: string;
    text: string;
    timestamp: string;
  };
  ready_for_orientation?: boolean;
  massage_therapy_new?: boolean;
  contact_information?: string;
  referral_method?: 'tpa' | 'ppms' | 'word_of_mouth' | 'other';
}

export interface ProviderType {
  id: string;
  fname: string;
  lname: string;
  email?: string;
  phone?: string;
  npi?: string;
}

export enum AppointmentStatusType {
  Proposed = 'proposed',
  Pending = 'pending',
  Booked = 'booked',
  Arrived = 'arrived',
  Fulfilled = 'fulfilled',
  Cancelled = 'cancelled',
  NoShow = 'noshow',
  EnteredInError = 'entered-in-error',
  CheckedIn = 'checked-in',
  Waitlist = 'waitlist',
}

export enum AppointmentStageType {
  Blasting = 'blasting',
  BlastingPriority = 'blasting-priority',
  BlastingSpecific = 'blasting-specific',
  Canceled = 'canceled',
  CanceledPending = 'canceled-pending',
  CanceledExpired = 'canceled-expired',
  CanceledConfirmed = 'canceled-confirmed',
  CanceledExtraneous = 'canceled-extraneous',
  CanceledExpiredServiceNotOffered = 'canceled-expired-service-not-offered',
  CanceledExpiredSpecificTherapistRequest = 'canceled-expired-specific-therapist-request',
  CanceledSuspended = 'canceled-suspended',
  Confirmed = 'confirmed',
  Completed = 'completed',
  Pending = 'pending',
  PendingNoblast = 'pending-noblast',
  PendingReschedule = 'pending-reschedule',
  PendingNoblastSuspended = 'pending-noblast-suspended',
  PendingNeverBlasting = 'pending-never-blasting',
  PendingPrioritized = 'pending-prioritized',
  NoShow = 'no-show',
}

export interface AppointmentType {
  id: string;
  address?: {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;
  };
  identifier?: Array<{
    system: string;
    value?: string | number;
  }>;
  provider?: ProviderType;
  duration: string | number;
  copied_into_hsrm: boolean;
  can_be_copied_into_hsrm: boolean;
  first_service_request_appointment: boolean;
  priority_review?: boolean;
  note_reviewed?: boolean;
  procedure_id?: string;
  fname?: string;
  lname?: string;
  birthday?: string;
  stage?: AppointmentStageType;
  status?: AppointmentStatusType;
  service_request_id: string;
  provider_id?: string;
  date?: string;
  time_segment?: string;
  minutes_duration?: number;
  start?: string;
  end?: string;
  referral_id?: string;
  extension?: Array<{
    url: string;
    value_boolean?: boolean;
    value_code?: string;
    value_integer: number;
    value_string?: string;
  }>;
  sessionNumber?: number;
  practitioner_id?: string;
  practitioner_name?: string;
  patient_name?: string;
  office_based?: boolean;
  ogAppointmentId?: string | number;
  created?: string;
  og_stage?: string;
}

export enum CompositionStatusType {
  Amended = 'amended',
  Approved = 'approved',
  EnteredInError = 'entered-in-error',
  Final = 'final',
  Preliminary = 'preliminary',
  Rejected = 'rejected',
  Submitted = 'submitted',
}

export enum CompositionTitleType {
  SOAP_NOTES = 'SOAP Notes',
  MEDICAL_REVIEW_NOTES = 'Medical Review Notes', // aka doctor notes
}

export enum CompositionCategorizeType {
  SOAP_NOTES = 'Progress Note',
  MEDICAL_REVIEW_NOTES = 'Consult Note', // aka doctor notes
}

export interface CompositionType {
  appointment_date: string;
  date_submitted: string;
  date_authored?: string;
  zcc_entry?: boolean;
  id: string;
  notes: Array<NoteType>;
  procedure_id: string;
  status: CompositionStatusType;
  title: CompositionTitleType;
  type: CompositionCategorizeType;
  lastPackaged?: string;
  meta?: {
    lastUpdated?: string;
    source?: string;
    tag?: Array<any>;
    versionId?: string;
  };
  version: number;
  submission_count?: number;
  rejection_note?: string;
  reviewed_by?: string;
  cpt_code: string;
  signatory_name: string;
  history?: Array<SoapNoteHistoryType>;
  milestone_appointment?: string;
}

export interface ApiCommentType {
  id: number;
  appointment_id: number;
  category: 'sticky';
  dt_edited: string;
  dt_inserted: string;
  member_id: number;
  member?: {
    [key: string]: any;
  };
  note: string;
  status: number;
}

export interface CommentType {
  id: string;
  appointmentId: string;
  dateEdited: string;
  dateCreated: string;
  memberId: string;
  memberName?: string;
  member?: {
    // raw from OG
    [key: string]: any;
  };
  text: string;
}

export interface ProviderCtrlType {
  blasted?: boolean;
  fname: string;
  id: string;
  ide?: string;
  label?: string;
  lname: string;
  profile_pic?: string;
  profile_pic_sm?: string;
  sex: string;
  value?: string;
}

export type SoapNoteHistoryType = {
  action_date_time: string;
  reviewed_by?: string;
  status: string;
  rejection_note?: string;
};
