import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { MedicalApi, TemplateApi, EvaluationApi, DocumentApi, ZrefApi } from 'apis';
import { QuestionnaireResponseType } from 'apis/evaluation';
import { useState, useUIStore, useCopyToClipboard } from 'utils/hooks';
import { downloadFile, joinString } from 'utils/helper';
import Modal, { ModalPropType } from '../templates/Modal';
import Grid from '@mui/material/Unstable_Grid2';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField, Icon } from 'components';
import styles from './style.module.scss';
import { CompositionType, FacilityType } from 'apis/medical';
import { DocumentType } from 'apis/document';
import { RequestLookupType, RequestType } from 'apis/zref';
import { DuplicateCopyBoldIcon } from '@zeel-dev/zeel-ui';
import uniq from 'lodash/uniq';

export type CopyRequestToHsrmProps = ModalPropType & {
  requestId: string;
};

export default function CopyRequestToHsrm({ requestId, onClose, scrollTop, modalProps }: CopyRequestToHsrmProps) {
  const { openAlert } = useUIStore();
  const { copyToClipboard } = useCopyToClipboard();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [request, setRequest] = useState<any>();
  const [facility, setFacility] = useState<FacilityType>();
  const [serviceRequest, setServiceRequest] = useState<any>();
  const [rfsDoc, setRfsDoc] = useState<DocumentType>();
  const [pdfUrl, setPdfUrl] = useState<any>();
  const [loadingRFSPDFPackage, setLoadingRFSPDFPackage] = useState(true);
  const rfsBlob = useRef<Blob>();
  const rfsPDFPackageBlob = useRef<Blob>();

  useEffect(() => {
    async function fetchData() {
      let rfs: RequestType, lookup: RequestLookupType;
      try {
        [rfs, lookup] = await Promise.all([ZrefApi.fetchRequest(requestId), ZrefApi.fetchRequestLookup(requestId)]);
        const _facility = await MedicalApi.fetchFacility(rfs.facility_id);
        setRequest(rfs);
        setFacility(_facility);
        setServiceRequest(lookup.initiating_service_request);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setLoadingRFSPDFPackage(false);
        setError('An error occurred while fetching the request');
        return;
      }

      try {
        rfsBlob.current = await TemplateApi.getRfsDocumentById(rfs.request_id);

        const serviceRequestId = lookup.initiating_service_request.id;
        const [questionnaireResponses, soapNotes] = await Promise.all([
          EvaluationApi.searchQuestionnaireResponseByServiceRequestId(serviceRequestId),
          MedicalApi.fetchAllSoapNote(serviceRequestId),
        ]);
        soapNotes.sort(
          (a: CompositionType, b: CompositionType) =>
            new Date(a.appointment_date).getTime() - new Date(b.appointment_date).getTime()
        );
        questionnaireResponses.sort(
          (a: QuestionnaireResponseType, b: QuestionnaireResponseType) => a.session_number - b.session_number
        );

        // Get initial and final evaluations
        const appointmentIds = questionnaireResponses
          .filter(
            (qr) =>
              qr.session_number === 1 || qr.session_number === lookup.initiating_service_request.quantityQuantity.value
          )
          .map((qr) => qr.appointment_id);

        const isSoapNoteOrEvaluationAvailable = appointmentIds.length > 0 || soapNotes.length > 0;
        if (!isSoapNoteOrEvaluationAvailable) {
          const url = window.URL.createObjectURL(rfsBlob.current);
          setPdfUrl(url);
        } else {
          const combineSoapNotesAndEvaluationsBlob = await TemplateApi.combineSoapNotesAndEvaluations(
            soapNotes.map(({ id }) => id),
            uniq(appointmentIds)
          );
          const [uploadedRfsDoc, combinedSoapNotesAndEvaluations] = await Promise.all([
            DocumentApi.uploadDocument(
              new File([rfsBlob.current], `RFS_${rfs.initiating_referral_number}.pdf`, {
                type: 'application/pdf',
              })
            ),
            DocumentApi.uploadDocument(combineSoapNotesAndEvaluationsBlob),
          ]);

          rfsPDFPackageBlob.current = await DocumentApi.mergeDocuments(
            {
              document_ids: [uploadedRfsDoc.id, combinedSoapNotesAndEvaluations.id],
              filename: 'Combined PDF',
            },
            false
          );
          const url = window.URL.createObjectURL(rfsPDFPackageBlob.current);
          setRfsDoc(uploadedRfsDoc);
          setPdfUrl(url);
        }
      } catch (e) {
        console.log(e);
        setError('An error occurred while merging PDFs');
      }
      setLoadingRFSPDFPackage(false);
    }

    fetchData();
  }, [requestId]);

  const onSubmit = async () => {
    try {
      await ZrefApi.setRequestAsCopied(request.request_id);

      try {
        let rfsDocToUpload = rfsDoc;

        const [uploadedRfsDoc, uploadedRfsPDFPackageDoc] = await Promise.all([
          ...(!rfsDoc && rfsBlob.current
            ? [
                DocumentApi.uploadDocument(
                  new File([rfsBlob.current], `RFS_${request.initiating_referral_number}.pdf`, {
                    type: 'application/pdf',
                  })
                ),
              ]
            : [Promise.resolve(undefined)]),
          ...(rfsPDFPackageBlob.current
            ? [
                DocumentApi.uploadDocument(
                  new File(
                    [rfsPDFPackageBlob.current],
                    `Additional_Services_Request_Package_${request.initiating_referral_number}.pdf`,
                    {
                      type: 'application/pdf',
                    }
                  )
                ),
              ]
            : [Promise.resolve(undefined)]),
        ]);

        if (uploadedRfsDoc) {
          rfsDocToUpload = uploadedRfsDoc;
        }

        if (rfsDocToUpload || uploadedRfsPDFPackageDoc) {
          await MedicalApi.addServiceRequestFiles(serviceRequest?.id, [
            ...(rfsDocToUpload
              ? [
                  {
                    description: 'RFS Document',
                    type: 'rfs',
                    url: rfsDocToUpload.location,
                  },
                ]
              : []),
            ...(uploadedRfsPDFPackageDoc
              ? [
                  {
                    description: 'Additional Services Request PDF Package',
                    type: 'medical-document',
                    url: uploadedRfsPDFPackageDoc.location,
                  },
                ]
              : []),
          ]);
        }
      } catch (e) {
        openAlert({ title: 'Failed to attach RFS documents', severity: 'error' });
      }
      openAlert({ title: 'Request Marked as Uploaded to HSRM and RFS documents are attached' });
      onClose?.(true);
    } catch (e) {
      setError('An error occurred while marking the request as uploaded to HSRM');
      scrollTop();
      console.error(e);
    }
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    disabled: true,
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      style: { pointerEvents: 'none' },
      endAdornment: (
        <InputAdornment position='end'>
          <DuplicateCopyBoldIcon size={24} />
        </InputAdornment>
      ),
    },
  };

  const copy = (value = '') => {
    copyToClipboard(value);
  };

  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={error}
      title='Upload Additional Services Request to HSRM'
      description={
        <>
          Follow these steps to upload the request PDF to HSRM.
          <br />
          Need help? Read the Process Overview.
        </>
      }
      className={styles.base}
      footerClassName={styles.footer}
      onSubmit={onSubmit}
      actions={[
        { label: 'Back', action: 'close' },
        { label: 'Finish & Mark As Uploaded', action: 'submit', disabled: loading || loadingRFSPDFPackage },
      ]}>
      <Grid className={styles.section} container spacing={3}>
        {!loading && (
          <Grid xs={12}>
            <table className={styles.table}>
              <tbody>
                <tr>
                  <td colSpan={4}>
                    <div>VAMC</div>
                    <div>{joinString([facility?.facility_id, facility?.name])}</div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <div>Internal Facility Notes (private within Zeel)</div>
                    <div>{facility?.internal_note?.text}</div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <div>External Facility Notes (visible to providers)</div>
                    <div>{facility?.external_note?.text}</div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <div>Patient History</div>
                    <div>{serviceRequest?.comorbidities}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        )}
        <Grid xs={12}>
          <p className={styles.title}>1. Find prior referral in HSRM portal</p>
        </Grid>
        <Grid xs={6} className={styles.copyWrapper} onClick={() => copy(request?.initiating_referral_number)}>
          <TextField value={request?.initiating_referral_number} label='Referral #' {...fieldProps} />
        </Grid>
      </Grid>
      <Grid className={styles.section} container spacing={3}>
        <Grid xs={12}>
          <p className={styles.title}>2. Initiate Request for Additional Services</p>
        </Grid>
      </Grid>
      <Grid className={styles.section} container spacing={3}>
        <Grid xs={12}>
          <p className={styles.title}>3. Upload Additional Services Request PDF Package</p>
        </Grid>
        <Grid xs={8} className={cn(styles.files)}>
          <p className={styles.label}>Additional Services Request PDF Package</p>
          <div className={styles.container}>
            <div
              className={cn(styles.file, { [styles['file--downloading']]: false })}
              onClick={() => downloadFile(pdfUrl, 'Additional_Services_Request_Package.pdf')}>
              {loadingRFSPDFPackage ? (
                <>
                  <CircularProgress size={24} color='inherit' />
                </>
              ) : (
                !!pdfUrl && (
                  <>
                    <Icon name='pdf' size={24} />
                    <p>Additional_Services_Request_Package.pdf</p>
                  </>
                )
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
}
