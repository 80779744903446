import React, { useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { useAuth, useIsTestDataCreator, useState, useUIStore, useZccService } from 'utils/hooks';
import { ZCCApi } from 'apis';
import { ZccPatientType, ZCCSortByParameters } from 'apis/zcc';
import { SectionLayout, Table, IconMenu, Icon, FilterButtonV3 } from 'components';
import PatientSidePanel from 'components/sidePanels/Patient';
import { c } from 'utils';
import styles from './style.module.scss';
import { PatientHoldKeys, PatientHoldStatus } from 'apis/medical';
import moment from 'moment';
import { CircleCrossedBoldIcon, Group } from '@zeel-dev/zeel-ui';
import { FilterType } from 'components/FilterButtonV3/FilterButtonV3.types';

// enum PatientActions {
//   UploadConsent = 'upload_consent',
// }

const exceptionFilters = [
  {
    id: 'needs_consent',
    label: 'Needs Consent',
    key: 'consents_completed',
    value: false,
  },
  {
    id: 'scheduling_hold',
    label: 'Scheduling Hold',
    key: 'network_status',
    value: ['scheduling'],
  },
  {
    id: 'medical_hold',
    label: 'Medical Hold',
    key: 'network_status',
    value: ['medical'],
  },
  {
    id: 'trust_and_safety_hold',
    label: 'Patient Review Hold',
    key: 'network_status',
    value: ['trust_and_safety'],
  },
];

const holdStatusLabelMap: Record<string, string> = {
  'MEDICAL HOLD': 'Medical Hold',
  'SCHEDULING HOLD': 'Scheduling Hold',
  'TRUST AND SAFETY HOLD': 'Patient Review Hold',
};

const defaultFilters = {
  test_data: false,
};

export default function PatientV2() {
  const isTestDataCreator = useIsTestDataCreator();

  const service = useZccService<ZccPatientType>(ZCCApi.fetchPatientItems, {
    initialParameters: {
      filters: defaultFilters,
    },
    exceptions: exceptionFilters,
    paramTypes: {
      test_data: 'boolean',
    },
    transformRequest: (params) => {
      if ((!isTestDataCreator && params.filters) || params.filters?.test_data === false) {
        delete params.filters.test_data;
      }
      return params;
    },
  });

  const [open, setOpen] = useState<boolean>(false);
  const [activeRowData, setActiveRowData] = useState<any>(null);
  const [highlightedRowId, setHighlightedRowId] = useState<string | null | undefined>();
  const { openModal, openAlert } = useUIStore();
  const { user } = useAuth();

  const rowClicked = (row: any) => {
    const { id } = row || {};
    if (open && activeRowData && id === activeRowData?.id) setOpen(false);
    else {
      setOpen(true);
      setActiveRowData(row);
    }
  };

  const sortChangeHandler = (sortModel: any) => {
    const sortBy: ZCCSortByParameters = {};
    sortModel?.forEach(({ field, sort }: any) => {
      sortBy[field] = sort;
    });
    if (!isEqual(service.parameters.sortBy, sortBy)) {
      service.setSortBy(sortBy);
    }
  };

  const highlightRow = (rowId: string) => {
    setHighlightedRowId(rowId);
    setTimeout(() => setHighlightedRowId(null), 5000);
  };

  // Adding appointments and notes
  const rows = useMemo(() => {
    const r = [
      ...(service.data || []).map((item: ZccPatientType, index: number) => {
        const {
          _id,
          actions,
          address,
          birth_date,
          gender,
          icn,
          og_member_id,
          patient_name,
          phone,
          ssn,
          zeel_id,
          consents,
          network_status,
        } = item;

        const consentsMissing = Object.values(consents || {}).flatMap(({ consents_missing }) => consents_missing || []);
        const consentsNotCompleted =
          !consents || Object.values(consents).some(({ consents_completed }) => !consents_completed);

        return {
          id: index,
          entity_id: _id.toString(),
          address,
          birth_date,
          gender,
          icn,
          og_member_id,
          patient_name,
          phone,
          ssn,
          zeel_id,
          actions,
          consents_completed: !consentsNotCompleted ? 'Yes' : 'No',
          consents_missing: consentsMissing,
          data: item,
          network_status,
          hold_status: holdStatusLabelMap[network_status],
        };
      }),
    ];
    return r;
  }, [service.data]);

  // Actions
  const openUploadConsentModal = (patientId: string) => {
    openModal({
      id: 'upload-consent',
      props: {
        patientId,
      },
      callback: (response: any) => {
        if (response) {
          service.refresh();
        }
      },
    });
  };

  const openPutPatientOnHoldModal = async (row: any) => {
    const { entity_id: patientId, patient_name: patientName } = row;
    openModal({
      id: 'put-patient-on-hold',
      props: {
        patientId,
        patientName,
      },
      callback: (res: boolean) => {
        if (res) {
          putActionNoteForOnHold(row, res);
        }
      },
    });
  };

  const openRemovePatientHoldModal = async (row: any) => {
    const { entity_id: patientId, patient_name: patientName, network_status } = row;
    openModal({
      id: 'remove-patient-hold',
      props: {
        patientId,
        patientName,
        holdType: holdStatusLabelMap[network_status],
      },
      callback: ({ shouldRemove, reason }) => {
        if (shouldRemove) {
          putActionNoteForRemovingHold(row, reason);
        }
      },
    });
  };

  const addActionNoteForHold = async (row: any, text: string) => {
    const { entity_id: patientId, data } = row;
    if (!patientId) return;

    const actionNote = data.action_note;

    const noteAction = actionNote?.length ? ZCCApi.createNote : ZCCApi.updateNote;

    await noteAction({
      note: [
        {
          text,
          pcc_agent: user?.pccAgentName,
        },
        ...(actionNote || []),
      ],
      noteType: 'action',
      patientId,
    });
  };

  const putActionNoteForOnHold = async (row: any, res: any) => {
    const { patient_name: patientName } = row;
    const { hold_status, hold_status_reason, hold_status_date } = res;
    try {
      const text = `${PatientHoldStatus[hold_status as PatientHoldKeys]} - ${
        hold_status_date ? moment(hold_status_date).format('MM/DD/YYYY') : 'indefinite'
      }${hold_status_reason ? ` - ${hold_status_reason}` : ''}`;

      await addActionNoteForHold(row, text);

      openAlert({
        title: `Patient on Hold${hold_status_date ? '' : ' Indefinitely'}`,
        description: `You'll need to manually resume scheduling for ${patientName}`,
      });
      service.refresh();
    } catch {
      openAlert({ title: `Failed to save the action note`, severity: 'error' });
    }
  };

  const putActionNoteForRemovingHold = async (row: any, reason?: string) => {
    const text = reason ? `Patient Hold Removed - ${reason}` : 'Patient Hold Removed';

    try {
      await addActionNoteForHold(row, text);

      openAlert({
        title: 'Patient Hold Removed',
      });
      service.refresh();
    } catch {
      openAlert({ title: `Failed to save the action note`, severity: 'error' });
    }
  };

  const columns = useMemo(
    () => [
      {
        id: 'contactPreference',
        label: ' ',
        width: 50,
        // eslint-disable-next-line react/display-name
        renderCell: ({ row }: any) => {
          const pref = row?.data?.contact_preference;
          if (!pref) return null;
          return (
            <div className={styles.contactIconWrapper}>
              <Icon color='grey-5' size={22} name={pref === 'phone-only' ? 'phone-filled' : 'phone'} />
              <p>{pref === 'phone-only' ? 'only' : 'preferred'}</p>
            </div>
          );
        },
      },
      { id: 'entity_id', label: 'Patient ID', width: 150 },
      {
        id: 'patient_name',
        label: 'Patient Name',
        width: 300,
        sortable: true,
        renderCell: ({ value, row }: any) => {
          return (
            <Group verticalAlign='center' horizontalGutter={1}>
              <span>{value}</span>
              {row?.network_status === 'REMOVED FROM NETWORK' && <CircleCrossedBoldIcon color='error' />}
            </Group>
          );
        },
      },
      { id: 'icn', label: 'ICN', width: 250 },
      { id: 'phone', label: 'Phone #', width: 200 },
      { id: 'ssn', label: 'SSN (last 4)', width: 200 },
      { id: 'hold_status', label: 'Hold Status', width: 150, sortable: false },
      { id: 'consents_completed', label: 'Consents Completed', width: 200, sortable: false },
      {
        id: 'consents_missing',
        label: 'Consents Missing',
        width: 200,
        sortable: false,
        renderCell: ({ value }: any) => {
          return (
            <div className={styles.ellipsisText}>
              {value.map((consent: string) => (
                <p key={consent} className={styles.ellipsisText}>
                  {c.CONSENT_FORMS[consent] ?? consent}
                </p>
              ))}
            </div>
          );
        },
      },
      {
        id: 'action',
        label: 'Actions',
        width: 500,
        sortable: false,
        renderCell: ({ row }: any) => {
          const actionItems = [
            { icon: 'upload', label: 'Upload Consent', onClick: () => openUploadConsentModal(row?.entity_id) },
            ...(row?.network_status !== 'SCHEDULING HOLD' &&
            row?.network_status !== 'MEDICAL HOLD' &&
            row?.network_status !== 'TRUST AND SAFETY HOLD'
              ? [
                  {
                    icon: 'calendar-appointment-pause',
                    label: 'Put Patient on Hold',
                    onClick: () => openPutPatientOnHoldModal(row),
                  },
                ]
              : [
                  {
                    icon: 'calendar-checkmark',
                    label: 'Remove Patient Hold',
                    onClick: () => openRemovePatientHoldModal(row),
                  },
                ]),
          ];

          return <IconMenu items={actionItems} />;
        },
      },
    ],
    []
  );

  const sideContent = (
    <PatientSidePanel
      open={open}
      onClose={() => setOpen(false)}
      id={activeRowData?.entity_id}
      onChange={() => {
        highlightRow(activeRowData?.id);
        service.refresh();
      }}
    />
  );

  return (
    <SectionLayout alert={service.error} title='Patients' sideContent={sideContent}>
      <div className={styles.tableHeader}>
        <FilterButtonV3
          initialValue={service.parameters.filters}
          defaultFilters={defaultFilters}
          onChange={(newFilters: any) => {
            service.setFilters(newFilters);
          }}
          searches={[
            {
              id: 'patient_name',
              label: 'Patient Name',
              type: 'auto-complete-search',
              fetchItems: ZCCApi.searchPatientName,
            },
          ]}
          exceptions={exceptionFilters}
          filters={[
            { id: '_id', label: 'Patient ID', type: 'text' },
            { id: 'icn', label: 'ICN', type: 'text' },
            { id: 'phone', label: 'Phone #', type: 'text' },
            { id: 'ssn', label: 'SSN (last 4)', type: 'text' },
            { id: 'address', label: 'Address', type: 'text' },
            { id: 'birth_date', label: 'Birth Date', type: 'date' },
            {
              id: 'gender',
              label: 'Gender',
              type: 'select',
              options: [
                { label: '-', value: '' },
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
                { label: 'Other', value: 'other' },
              ],
            },
            { id: 'zeel_id', label: 'Zeel Member ID', type: 'text' },
            ...(isTestDataCreator
              ? [
                  {
                    id: 'test_data',
                    label: 'Test Data',
                    type: 'segments',
                    permanent: true,
                    options: [
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ],
                  } as FilterType,
                ]
              : []),
            // {
            //   id: 'actions',
            //   label: 'Action Type',
            //   type: 'select-multiple',
            //   options: [{ label: 'Upload Consent', value: PatientActions.UploadConsent }],
            //   size: 'half',
            // },
          ]}
        />
      </div>
      <Table
        className={styles.table}
        loading={service.loading}
        name={`${rows.length} Patients`}
        columns={columns}
        rows={rows}
        onRowClick={(row: any) => rowClicked(row)}
        activeRowId={open ? activeRowData?.rowId : undefined}
        highlightedRowId={highlightedRowId}
        page={service.parameters.page}
        pageSize={service.parameters.pageSize}
        serverPagination
        serverRowCount={service.total}
        onPageChange={(page: any) => service.setPage(page)}
        onPageSizeChange={(pageSize: any) => service.setPageSize(pageSize)}
        rowsPerPageOptions={[100]}
        sortModel={service.sortModel}
        onSortModelChange={sortChangeHandler}
      />
    </SectionLayout>
  );
}
