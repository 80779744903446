import AddEditLocation from './AddEditLocation';
import AddReferral from './AddReferral';
import AddSoapNote from './AddSoapNote';
import AppointmentMedicalReview from './AppointmentMedicalReview';
import AssignNewInOfficeProvider from './AssignNewInOfficeProvider';
import AutomatedReviewSettings from './AutomatedReviewSettings';
import BookAppointment from './BookAppointment';
import BookAppointmentForPatient from './BookAppointmentForPatient';
import CombineFiles from './CombineFiles';
import Confirm from './Confirm';
import ContextualDocumentUpload from './ContextualDocumentUpload';
import CopyAppointmentToHsrm from './CopyAppointmentToHsrm';
import CopyAppointmentToHsrmV2 from './CopyAppointmentToHsrmV2';
import CopyRequestToHsrm from './CopyRequestToHsrm';
import CopyRevocationToHsrm from './CopyRevocationToHsrm';
import CopySoapNoteOrEvaluationToHsrm from './CopySoapNoteOrEvaluationToHsrm';
import CreateMemberAccount from './CreateMemberAccount';
import CreatingPDF from './CreatingPDF';
import DocumentPDFView from './DocumentPDFView';
import EvaluationPDFView from './EvaluationPDFView';
import Info from './Info';
import LinkPatientToMember from './LinkPatientToMember';
import ParkingLocation from './ParkingLocation';
import Patient from './Patient';
import PDFCreated from './PDFCreated';
import PickCalendarDate from './PickCalendarDate';
import ProviderNotesReview from './ProviderNotesReview';
import ProcessEFaxDocument from './ProcessEFaxDocument';
import PutPatientOnHold from './PutPatientOnHold';
import ReadOnlySoapNotesModal from './ReadOnlySoapNotesModal';
import RejectRequestForAdditionalServices from './RejectRequestForAdditionalServices';
import RejectRFS from './RejectRFS';
import RemovePatientHold from './RemovePatientHold';
import RequestForAdditionalServices from './RequestForAdditionalServices';
import RevokeReferral from './RevokeReferral';
import RFSReview from './RFSReview';
import SoapNotePDFView from './SoapNotePDFView';
import SoapNoteView from './SoapNoteView';
import SwitchProviderOffice from './SwitchProviderOffice';
import SwitchToInHomeReferral from './SwitchToInHomeReferral';
import TextConsentFormLink from './TextConsentFormLink';
import UploadConsent from './UploadConsent';
import UpdateReferralExpirationDate from './UpdateReferralExpirationDate';
import RFSSnooze from './RFSSnooze/RFSSnooze';
import ReferralPicker from './ReferralPicker';
import SubmitRFS from './SubmitRFS';

const modals = {
  'add-edit-location': AddEditLocation,
  'add-referral': AddReferral,
  'add-soap-note': AddSoapNote,
  'appointment-medical-review': AppointmentMedicalReview,
  'assign-new-in-office-provider': AssignNewInOfficeProvider,
  'automated-review-settings': AutomatedReviewSettings,
  'book-appointment': BookAppointment,
  'book-appointment-for-patient': BookAppointmentForPatient,
  'combine-files': CombineFiles,
  confirm: Confirm,
  'contextual-document-upload': ContextualDocumentUpload,
  'copy-appointment-to-hsrm': CopyAppointmentToHsrm,
  'copy-appointment-to-hsrm-v2': CopyAppointmentToHsrmV2,
  'copy-request-to-hsrm': CopyRequestToHsrm,
  'copy-revocation-to-hsrm': CopyRevocationToHsrm,
  'copy-soap-note-or-evaluation-to-hsrm': CopySoapNoteOrEvaluationToHsrm,
  'create-member-account': CreateMemberAccount,
  'creating-pdf': CreatingPDF,
  'document-pdf-view': DocumentPDFView,
  'evaluation-pdf-view': EvaluationPDFView,
  info: Info,
  'link-patient-to-member': LinkPatientToMember,
  'pdf-created': PDFCreated,
  'parking-location': ParkingLocation,
  patient: Patient,
  'pick-calendar-date': PickCalendarDate,
  'provider-notes-review': ProviderNotesReview,
  'process-e-fax-document': ProcessEFaxDocument,
  'put-patient-on-hold': PutPatientOnHold,
  'read-only-soap-notes': ReadOnlySoapNotesModal,
  'rfs-review': RFSReview,
  'rfs-snooze': RFSSnooze,
  'referral-picker': ReferralPicker,
  'reject-rfs': RejectRFS,
  'submit-rfs': SubmitRFS,
  'reject-request-for-additional-services': RejectRequestForAdditionalServices,
  'remove-patient-hold': RemovePatientHold,
  'request-for-additional-services': RequestForAdditionalServices,
  'revoke-referral': RevokeReferral,
  'soap-note-pdf-view': SoapNotePDFView,
  'soap-note-view': SoapNoteView,
  'switch-provider-office': SwitchProviderOffice,
  'switch-to-in-home-referral': SwitchToInHomeReferral,
  'text-consent-form-link': TextConsentFormLink,
  'upload-consent': UploadConsent,
  'update-referral-expiration-date': UpdateReferralExpirationDate,
};

export default modals;
