import { AddressType } from 'apis/medical';
import { ZRefAddressType } from 'apis/zref';
import { Maybe, Address } from 'generated/graphql';
import moment from 'moment';

export function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const matchFirst = cleaned.match(/^(1|)?(\d{3})$/);
  const matchSecond = cleaned.match(/^(1|)?(\d{3})(\d{3})$/);
  const matchFull = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  let formatted = phoneNumberString || '';

  if (matchFull) {
    const intlCode = matchFull[1] ? '+1 ' : '';
    formatted = [intlCode, '(', matchFull[2], ') ', matchFull[3], '-', matchFull[4]].join('');
  }
  if (matchSecond) {
    const intlCode = matchSecond[1] ? '+1 ' : '';
    formatted = [intlCode, '(', matchSecond[2], ') ', matchSecond[3], '-'].join('');
  }
  if (matchFirst) {
    const intlCode = matchFirst[1] ? '+1 ' : '';
    formatted = [intlCode, '(', matchFirst[2], ') '].join('');
  }

  return formatted.substring(0, (formatted || '')[0] === '+' ? 17 : 14);
}

export function normalizePhoneNumber(phoneNumberString: string) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const matchFirst = cleaned.match(/^(1|)?(\d{3})$/);
  const matchSecond = cleaned.match(/^(1|)?(\d{3})(\d{3})$/);
  const matchFull = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  let formatted = phoneNumberString || '';

  if (matchFull) {
    formatted = ['(', matchFull[2], ') ', matchFull[3], '-', matchFull[4]].join('');
  }
  if (matchSecond) {
    formatted = ['(', matchSecond[2], ') ', matchSecond[3], '-'].join('');
  }
  if (matchFirst) {
    formatted = ['(', matchFirst[2], ') '].join('');
  }

  return formatted.substring(0, 14);
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function scrollTop(id: string | null = null) {
  const element = (document as any).querySelector(id ? `#${id}` : 'body');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

export function parseJwt(token: string) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

export function downloadFile(url: string, fileName: string) {
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
}

export function joinString(arr: Array<string | undefined>, separator = ' ') {
  // Filter out falsy strings and join
  return arr.filter(Boolean).join(separator);
}

export function formatGraphQLAddress(address?: Maybe<Address>) {
  if (!address) return '';
  const part1 = joinString([address.line?.[0], address.city], ' ');
  const part2 = joinString([address.state, address.postalCode], ' ');
  return joinString([part1, part2], ', ');
}

export function formatAddress(address?: AddressType) {
  if (!address) return '';
  return joinString([address.line?.[0], address.city, address.state, address.postal_code], ', ');
}

export function splitAddress(address: string) {
  const [postalCode = '', state = '', city = '', ...rest] = address.split(', ').reverse();
  const [line1 = '', line2 = ''] = rest.reverse();

  return {
    city,
    line1,
    line2,
    postalCode,
    state,
  };
}

export const convertZccToZRefAddress = (address: AddressType): ZRefAddressType => {
  const { city, line = [], postal_code, state } = address;
  return {
    line_1: line[0],
    line_2: line[1],
    locality: city,
    region: state,
    postal: postal_code,
  };
};

export function getInputReadOnlyProps(className?: string) {
  return {
    variant: 'standard',
    InputProps: { disableUnderline: true, readOnly: true, className },
    InputLabelProps: { shrink: true },
  };
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

export function getCalendarTime(timestamp: string, utc = false, format = 'MMM DD', showTime = false): string {
  const date = utc ? moment.utc(timestamp) : moment(timestamp);
  const dateTime = date.local().calendar({
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastWeek: format,
    nextWeek: format,
    sameElse: format,
  });

  if (showTime && (dateTime === 'Yesterday' || dateTime === 'Today')) {
    return `${dateTime} ${date.local().format('h:mma')}`;
  }

  return dateTime;
}

export const getDocumentUrl = (documentId: string) => `${process.env.REACT_APP_NG_API}/document/v1/${documentId}`;
