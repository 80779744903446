import api from 'apis';

export interface DocumentMetaType {
  filename: string;
  filesize: number;
  mimetype: string;
  tags: string[];
  created_utc: string;
  updated_utc: string;
  user: string;
}

export interface DocumentType {
  id: string;
  location: string;
  meta: DocumentMetaType;
}

/**
 * Uploads a binary pdf to s3 and returns document uri.
 * Refer to: https://ng.dev.zeel.com/zref/v1/ui/#/
 */
export const uploadDocument = async (file: File | Blob, meta: any = null): Promise<DocumentType> => {
  const formData = new FormData();
  formData.append('document', file);
  if (meta) formData.append('meta', meta);

  const response = await api.post({
    path: '/document/v1',
    auth: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });

  return response;
};

export const uploadDocumentToHiOperator = async (file: File | Blob, meta: any = null): Promise<DocumentType> => {
  const formData = new FormData();
  formData.append('document', file);
  if (meta) formData.append('meta', meta);

  const response = await api.post({
    path: '/document/v1/hioperator',
    auth: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });

  return response;
};

export const createPdf = async (content: any): Promise<DocumentType> => {
  const response = await api.post({
    path: `/document/v1/pdf`,
    auth: true,
    data: content,
  });
  return response;
};

export function mergeDocuments(content: any, savePdf?: true): Promise<DocumentType>;
export function mergeDocuments(content: any, savePdf: false): Promise<Blob>;
export async function mergeDocuments(content: any, savePdf = true): Promise<DocumentType | Blob> {
  const response = await api.post({
    path: `/document/v1/pdf/_merge_documents?save_pdf=${savePdf.toString()}`,
    auth: true,
    data: content,
    ...(!savePdf ? { responseType: 'blob' } : {}),
  });
  return response;
}

export const downloadDocument = async (documentId: string): Promise<any> => {
  const response = await api.get({
    path: `/document/v1/${documentId}`,
    auth: true,
    responseType: 'blob',
  });

  return response;
};

export const fetchDocumentMeta = async (documentId: string): Promise<DocumentMetaType> => {
  const response = await api.get({
    path: `/document/v1/${documentId}/meta`,
    auth: true,
  });

  return response;
};

export const getFormTemplateFields = async (templateId: string): Promise<any> => {
  const response = await api.get({
    path: `/document/v1/template/${templateId}/fields`,
    auth: true,
  });
  return response;
};

export const fillFormTemplate = async (templateId: string, fields: any): Promise<any> => {
  const formData = new FormData();
  formData.append('fieldset', JSON.stringify(fields));

  const response = await api.post({
    path: `/document/v1/template/${templateId}/fields`,
    auth: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
  return response;
};
