import api from 'apis';
import { CancelToken } from 'axios';
import { transformApiRequests } from './helper';
import { ApiServiceRequestType, CompositionType, FacilityType, ServiceRequestType, InsuranceType } from 'apis/medical';
import { transformApiServiceRequests } from 'apis/medical/helper';
import moment from 'moment';

export const fetchBodyParts = async (): Promise<Array<string>> => {
  const bodyParts = await api.get({
    path: '/zref/v1/enums/body_parts',
    auth: true,
  });
  return bodyParts;
};

export const fetchDiagnosisCodes = async (): Promise<Array<string>> => {
  const diagnosisCodes = await api.get({
    path: '/zref/v1/enums/diagnosis_codes',
    auth: true,
  });
  return diagnosisCodes;
};

export const fetchPatientRelationshipTypes = async (): Promise<Array<string>> => {
  const patientRelationshipTypes = await api.get({
    path: '/zref/v1/enums/patient_relationship_types',
    auth: true,
  });
  return patientRelationshipTypes;
};

export const extractReferralDataFromPdf = async (file: File): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('_', '_'); // to workaround a backend library bug

  const pdfData = await api.post({
    path: '/zref/v1/seoc/_extract',
    auth: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });

  return pdfData;
};

/**
 * Calls zref to ingest a referral in the system. The referral will then
 * take 5 minutes to be visible as a service-request from the medical_massage api.
 * Refer to: https://ng.dev.zeel.com/zref/v1/ui/#/
 **/
export const createReferral = async (referral: ClientReferralType): Promise<any> => {
  const response = await api.post({
    path: '/zref/v1/referrals',
    auth: true,
    data: referral,
  });
  return response;
};

/**
 * Fetch all referrals that are being ingested.
 * Refer to: https://ng.dev.zeel.com/zref/v1/ui/#/
 */
export const fetchReferrals = async (params: { icn?: string; referralNumber?: string }): Promise<Array<any>> => {
  // referral type isn't defined yet
  const { icn, referralNumber } = params || {};
  const response = await api.get({
    path: '/zref/v1/referrals',
    auth: true,
    data: {
      patient_icn: icn,
      referral_number: referralNumber,
    },
  });

  return response?.items || [];
};

/**
 * Fetch a request by id
 */
export const fetchRequest = async (requestId: string): Promise<RequestType> => {
  const response = await api.get({
    path: `/zref/v1/requests/${requestId}`,
    auth: true,
  });
  const transformedRequest = transformApiRequests([response])?.[0];
  return transformedRequest;
};

/**
 * Lookup associated medical records
 */
export const fetchRequestLookup = async (requestId: string): Promise<RequestLookupType> => {
  const response = await api.get({
    path: `/zref/v1/requests/${requestId}/_lookup`,
    auth: true,
  });

  return {
    initiating_service_request: transformApiServiceRequests([response.initiating_service_request])[0],
    relevant_compositions: response.relevant_compositions,
  };
};

/**
 * Update a request
 */
export const updateRequest = async (requestId: string, data?: any): Promise<RequestType> => {
  const response = await api.patch({
    path: `/zref/v1/requests/${requestId}`,
    auth: true,
    data,
  });
  const transformedRequest = transformApiRequests([response])?.[0];
  return transformedRequest;
};

/**
 * Accept a request
 */
export const acceptRequest = async (requestId: string): Promise<any> => {
  const response = await api.put({
    path: `/zref/v1/requests/${requestId}/accepted`,
    auth: true,
  });
  return response;
};

export const setRequestAsCopied = async (requestId: string): Promise<any> => {
  const response = await api.put({
    path: `/zref/v1/requests/${requestId}/final`,
    auth: true,
  });
  return response;
};

/**
 * Reject a request
 */
export const rejectRequest = async (requestId: string, reason?: string): Promise<any> => {
  const response = await api.put({
    path: `/zref/v1/requests/${requestId}/rejected`,
    auth: true,
    data: {
      reason,
    },
  });
  return response;
};

export const submitRFS = async (
  serviceRequestId: string,
  signatoryName: string,
  progressToDate: string,
  reason: string
): Promise<any> => {
  const signatoryDate = moment(new Date()).format('YYYY-MM-DD');

  const response = await api.post({
    path: `/providers/rfs_create`,
    version: 'og-cms',
    auth: true,
    data: {
      service_request_id: serviceRequestId,
      number_of_visits: 12,
      signatory_name: signatoryName,
      signatory_date: signatoryDate,
      progress_to_date: progressToDate,
      reason_for_requested_service_and_scheduling_instructions: reason,
    },
    throwOGError: true,
  });
  return response;
};

export const fetchRequests = async (statuses: string[], cancelToken?: CancelToken) => {
  const response = await api.get({
    path: '/zref/v1/requests',
    auth: true,
    data: {
      statuses: statuses,
      pageSize: 10000,
    },
    cancelToken,
  });

  const transformedRequests = transformApiRequests(response.items);
  return { requests: transformedRequests, total: response.total };
};

export const fetchRequestsByReferralId = async (referralNumber: string): Promise<RequestType[]> => {
  const response = await api.get({
    path: `/zref/v1/requests`,
    auth: true,
    data: {
      initiating_referral_numbers: referralNumber,
    },
  });
  const transformedRequests = transformApiRequests(response.items);
  return transformedRequests;
};

export interface ZRefAddressType {
  address_id?: string;
  country?: string;
  line_1?: string;
  line_2?: string;
  locality?: string;
  postal?: string;
  region?: string;
  created_at?: string;
  updated_at?: string;
}

export type ReferralType = 'SEOC' | 'AHF';

export interface ApiPatientZrefType {
  address?: ZRefAddressType & {
    patient_id?: string;
  };
  birth_date?: string;
  created_at: string;
  edipi?: string;
  email?: string;
  family_name?: string;
  given_name?: Array<string>;
  gender?: string;
  icn?: string;
  patient_id: string;
  phone?: string;
  referral?: any;
  referral_id?: string;
  request_id?: string;
  ssn?: string;
  updated_at?: string;
}

export interface ApiProviderType {
  created_at: string; // YYYY-MM-DD
  email: string;
  family_name: string;
  given_name: Array<string>;
  group_practice_name: string;
  group_practice_npi: string;
  individual_practice_name: string;
  individual_practice_npi: string;
  phone: string;
  provider_id: number;
  updated_at: string; // YYYY-MM-DD
}

export interface ServiceType {
  charges?: number;
  duration?: number; // in minutes
  description?: string;
  duration_follow_up?: number;
  duration_complex_follow_up?: number;
  duration_initial?: number;
}

export interface ClientReferralType {
  assigned_provider?: Partial<ApiProviderType>;
  referral_id?: string;
  referral_number: string;
  type: string;
  issue_date: string; // YYYY-MM-DD
  expiration_date: string; // YYYY-MM-DD
  facility_id: string;
  chief_complaint: string;
  provisional_diagnosis: string;
  icd_10_codes?: Array<string | number>;
  body_parts?: Array<string | number>;
  // impressions?: Array<{
  //   body_parts?: Array<string>;
  //   diagnosis_code?: Array<string>;
  //   description?: string;
  // }>;
  comorbidities?: string;
  insurer: string;
  office_based?: boolean;
  medical_documentation?: string;
  associated_documents?: Array<{ description: string; url: string }> | null;
  document_url: string;
  patient: {
    address: ZRefAddressType;
    caregivers?: Array<{
      birth_date?: string;
      email?: string;
      family_name?: string;
      gender?: string;
      given_name?: Array<string>;
      phone?: string;
      relation_to_patient?: string;
    }>;
    icn: string;
    edipi?: string;
    given_name: any;
    family_name: string;
    birth_date: string;
    ssn?: string;
    phone: string;
    phone_business?: string;
    phone_mobile?: string;
    phone_only?: boolean;
    phone_preferred?: boolean;
    email?: string;
    mobile?: string;
    gender?: string;
    ahf_member_id?: string;
  };
  services: Array<ServiceType>;
  insurance?: Array<InsuranceType>;
  condition_type?: string;
  test_data: boolean;
}

export enum RequestStatusType {
  Amended = 'amended',
  Accepted = 'accepted',
  EnteredInError = 'entered-in-error',
  Final = 'final',
  Preliminary = 'preliminary',
  Rejected = 'rejected',
  Submitted = 'submitted',
}

export interface ApiRequestType {
  _lookup?: ApiRequestLookupType;
  anticipated_date_care_begins: string; // YYYY-MM-DD
  anticipated_date_care_ends: string; // YYYY-MM-DD
  created_at: string; // YYYY-MM-DD
  deleted_at?: string; // YYYY-MM-DD
  document_url: string;
  facility: FacilityType;
  facility_id: string;
  history?: Array<{ action_id: number; created_at: string; type: string; user: string }>;
  initiating_referral: any;
  initiating_referral_number: string;
  medical_specialties?: Array<string>;
  number_of_visits: number;
  patient: ApiPatientZrefType;
  provider: ApiProviderType;
  provider_id: string;
  provisional_diagnosis: string;
  rejected_reason?: string;
  request_id: string;
  referral_number: string;
  service_requested: string;
  service_type: string;
  status: RequestStatusType;
  supporting_services?: Array<string>;
  surgical_specialties?: Array<string>;
  type_of_request: string;
  updated_at?: string; // YYYY-MM-DD T HH:MM:SS.mmmmmm
  hold_until_date?: string;
  first_submitted_date?: string;
}

export interface ApiRequestLookupType {
  initiating_service_request: ApiServiceRequestType;
  relevant_compositions: CompositionType[];
}

export interface RequestLookupType {
  initiating_service_request: ServiceRequestType;
  relevant_compositions: CompositionType[];
}

export type RequestType = ApiRequestType & { _lookup: RequestLookupType };
