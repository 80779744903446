import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
// import { useRole } from 'utils/hooks';
import { routes, c } from 'utils';
import { Route, Layout } from 'components';
// import styles from './style.module.scss';

import {
  Practitioners,
  Referrals,
  Patients,
  Tasks,
  MedicalReview,
  MedicalReviewOld,
  Appointments,
  TableTest,
  PatientsGraphQL,
  ReferralsOld,
  AppointmentsOld,
  VAFacility,
  EFax,
  ReviewSoapNotes,
} from './sections';

export default function Medical() {
  const tabs: Array<any> = [
    { label: 'Referrals', route: routes.MEDICAL_REFERRALS },
    { label: 'Patients', route: routes.MEDICAL_PATIENTS },
    { label: 'Practitioners', route: routes.MEDICAL_PRACTITIONERS },
    { label: 'Appointments', route: routes.MEDICAL_APPOINTMENTS },
    { label: 'Tasks', route: routes.MEDICAL_TASKS },
    { label: 'Medical Review', route: routes.MEDICAL_MEDICAL_REVIEW },
    { label: 'VA Facilities', route: routes.MEDICAL_FACILITIES },
    { label: 'eFax Queue', route: routes.MEDICAL_EFAX },
    { label: 'Deprecated:', separator: true },
    { label: 'Referrals 1.0', route: routes.MEDICAL_REFERRALS_OLD, faded: true },
    { label: 'Appointments 1.0', route: routes.MEDICAL_APPOINTMENTS_OLD, faded: true },
    { label: 'Medical Review 1.0', route: routes.MEDICAL_MEDICAL_REVIEW_OLD, faded: true },
    // { label: 'Graph QL:', separator: true },
    // { label: 'Patients', route: routes.MEDICAL_PATIENTS_GRAPH_QL },
  ];

  return (
    <Layout tabs={tabs}>
      <Switch>
        {/* Medical 2.0 pages */}
        <Route path={routes.MEDICAL_REFERRALS} component={Referrals} />
        <Route path={routes.MEDICAL_PATIENTS} component={Patients} />
        <Route path={routes.MEDICAL_PRACTITIONERS} component={Practitioners} />
        <Route path={routes.MEDICAL_APPOINTMENTS} component={Appointments} />
        <Route path={routes.MEDICAL_FACILITIES} component={VAFacility} />
        <Route path={routes.MEDICAL_TASKS} component={Tasks} />
        <Route path={routes.MEDICAL_EFAX} component={EFax} />
        <Route
          path={routes.MEDICAL_MEDICAL_REVIEW}
          component={MedicalReview}
          role={[c.ROLES.MEDICAL.ADMIN, c.ROLES.MEDICAL.DOCTOR]}
        />
        <Route path={routes.MEDICAL_PATIENTS_GRAPH_QL} component={PatientsGraphQL} />

        {/* Deprecated 1.0 pages */}
        <Route path={routes.MEDICAL_REFERRALS_OLD_ROOT} component={ReferralsOld} />
        <Route path={routes.MEDICAL_APPOINTMENTS_OLD_ROOT} component={AppointmentsOld} />
        <Route path={routes.MEDICAL_MEDICAL_REVIEW_OLD_ROOT} component={MedicalReviewOld} />

        {/* Review routes  */}
        <Route path={routes.MEDICAL_REVIEW_SOAP_NOTES} component={ReviewSoapNotes} />
        {/* Testing routes */}
        <Route path={routes.TABLE_TEST} component={TableTest} role={[c.ROLES.MEDICAL.ADMIN]} />

        <Redirect to={routes.MEDICAL_REFERRALS} />
      </Switch>
    </Layout>
  );
}
