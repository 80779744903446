import React from 'react';
import Grid from '@mui/material/Grid';
import styles from './style.module.scss';
import { RFSReviewHeaderProps } from './RFSReview.types';
import { joinString } from 'utils/helper';
import moment from 'moment';

export default function RFSReviewHeader({ loading, serviceRequest, facility }: RFSReviewHeaderProps) {
  return (
    <>
      {!loading && (
        <Grid item xs={12}>
          <table className={styles.serviceRequestTable}>
            <tbody>
              <tr>
                <td colSpan={2}>
                  <div>Referral Issue Date</div>
                  <div>{serviceRequest?.issue_date && moment(serviceRequest.issue_date).format('MM/DD/YYYY')}</div>
                </td>
                <td colSpan={2}>
                  <div>Referral Expiration</div>
                  <div>
                    {serviceRequest?.expiration_date ? moment(serviceRequest.expiration_date).format('MM/DD/YYYY') : ''}
                  </div>
                </td>
                <td colSpan={2}>
                  <div>Treatment Cadence</div>
                  <div>{serviceRequest?.treatment_cadence}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <div>VAMC</div>
                  <div>{joinString([facility?.facility_id, facility?.name])}</div>
                </td>
                <td colSpan={2}>
                  <div>Referral Type</div>
                  <div>{serviceRequest?.condition_type}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={6}>
                  <div>Internal Facility Notes (private within Zeel)</div>
                  <div>{facility?.internal_note?.text}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={6}>
                  <div>External Facility Notes (visible to providers)</div>
                  <div>{facility?.external_note?.text}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={6}>
                  <div>Patient History</div>
                  <div>{serviceRequest?.comorbidities}</div>
                </td>
              </tr>
              {serviceRequest?.hasClinicalRationaleForExtension && (
                <tr>
                  <td colSpan={6}>
                    <div>Clinical Rationale For Extension</div>
                    {serviceRequest?.duration_ext_multiple_body_parts && (
                      <div>
                        <div className={styles.extCheckbox}>
                          <input type='checkbox' checked disabled id='duration_ext_multiple_body_parts' />
                          <label htmlFor='duration_ext_multiple_body_parts'>
                            Multiple body parts require treatment as indicated by the provisional diagnosis
                          </label>
                        </div>
                        <div className={styles.extDetails}>
                          <div>Which specific body parts?</div>
                          <div>{serviceRequest?.duration_ext_multiple_body_parts_details}</div>
                        </div>
                      </div>
                    )}
                    {serviceRequest?.duration_ext_regions && (
                      <div>
                        <div className={styles.extCheckbox}>
                          <input type='checkbox' checked disabled id='duration_ext_regions' />
                          <label htmlFor='duration_ext_regions'>
                            Regions above and below the primary treatment area require concurrent treatment to maximize
                            patient function
                          </label>
                        </div>
                        <div className={styles.extDetails}>
                          <div>Which regions and muscles?</div>
                          <div>{serviceRequest?.duration_ext_regions_details}</div>
                        </div>
                      </div>
                    )}
                    {serviceRequest?.duration_ext_symptoms && (
                      <div>
                        <div className={styles.extCheckbox}>
                          <input type='checkbox' checked disabled id='duration_ext_symptoms' />
                          <label htmlFor='duration_ext_symptoms'>
                            Due to the complex nature of the patient&apos;s symptoms, extra time is required to properly
                            address the issues
                          </label>
                        </div>
                        <div className={styles.extDetails}>
                          <div>Patient&apos;s symptoms that require them to need more time...</div>
                          <div>{serviceRequest?.duration_ext_symptoms_details}</div>
                        </div>
                      </div>
                    )}
                    {serviceRequest?.duration_ext_other && (
                      <div>
                        <div className={styles.extCheckbox}>
                          <input type='checkbox' checked disabled id='duration_ext_other' />
                          <label htmlFor='duration_ext_other'>Other</label>
                        </div>
                        <div className={styles.extDetails}>
                          <div>Details</div>
                          <div>{serviceRequest?.duration_ext_other_details}</div>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Grid>
      )}
    </>
  );
}
