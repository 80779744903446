import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import cn from 'classnames';
import { MedicalApi, DocumentApi, EvaluationApi } from 'apis';
import { AppointmentType, ServiceRequestType, CompositionType, DocumentType, PatientType } from 'apis/medical';
import { useState, useUIStore, useForm, useCombineFiles, useCopyToClipboard } from 'utils/hooks';
import Modal, { ModalPropType } from '../templates/Modal';
import Grid from '@mui/material/Unstable_Grid2';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import { DuplicateCopyBoldIcon } from '@zeel-dev/zeel-ui';
import { TextField, FilesContainer } from 'components';
import styles from './style.module.scss';
import { DocumentMetaType } from 'apis/document';

export type CopyAppointmentToHsrmProps = ModalPropType & {
  appointment: AppointmentType;
};

export default function CopyAppointmentToHsrm({
  appointment,
  onClose,
  scrollTop,
  modalProps,
}: CopyAppointmentToHsrmProps) {
  const { openAlert } = useUIStore();
  const { copyToClipboard } = useCopyToClipboard();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [serviceRequest, setServiceRequest] = useState<any>();
  const [patient, setPatient] = useState<any>();
  const [notes, setNotes] = useState<CompositionType[]>([]);
  const [evaluations, setEvaluations] = useState<DocumentType[]>([]);
  const toAdd = appointment?.first_service_request_appointment === true && appointment?.stage === 'confirmed';
  const { bind, form } = useForm({
    expirationDate: null,
  });

  const { openCombineFiles, uploadedFiles, combinedSoapNoteIds, combinedQuestionnaireResponseIds } = useCombineFiles({
    serviceRequest,
    notes,
    evaluations,
    patient,
    onError: (e) => {
      setError(e);
      scrollTop();
    },
  });

  useEffect(() => {
    async function fetchData() {
      let sr: ServiceRequestType | null = null;
      let p: PatientType | null = null;
      if (appointment?.service_request_id) {
        try {
          sr = (await MedicalApi.fetchServiceRequest(appointment.service_request_id)) as ServiceRequestType;
          setServiceRequest(sr);
          if (sr?.patient_id) {
            p = await MedicalApi.fetchPatient(sr.patient_id);
            setPatient(p);
          }

          let documents =
            sr?.additional_documents
              ?.filter((doc) => doc?.type === 'evaluation')
              .map(
                (doc: DocumentType): DocumentType => ({
                  url: doc.url,
                  description: doc.description,
                  id: (doc.url || '').split('/').pop() as string,
                  type: doc?.type,
                })
              ) ?? [];

          const promises: Array<Promise<DocumentMetaType | boolean>> = [];
          documents.forEach((d: any) => {
            promises.push(DocumentApi.fetchDocumentMeta(d.id).catch(() => false));
          });

          const fileMetas = await Promise.all(promises);
          const idsToRemove: Array<string> = [];
          (fileMetas || []).forEach((meta: any, i: number) => {
            if (!meta) {
              idsToRemove.push(documents[i].id);
            } else {
              const { filename: fileName, filesize: fileSize, mimetype: mimeType, tags, created_utc: created } = meta;
              documents[i].meta = {
                fileName,
                fileSize,
                mimeType,
                tags,
                created,
              };
            }
          });

          // removing ids where fetching meta failed (doc doesn't exist 404)
          documents = documents.filter((doc: DocumentType) => !idsToRemove.includes(doc.id));
          setEvaluations(documents);
        } catch (e) {
          setError('An error occured while fetching the referral #');
        }
      }
      // Fetch all compositions for a service request
      if (appointment?.service_request_id && appointment?.stage !== 'confirmed') {
        try {
          const pNotes = await MedicalApi.fetchAllSoapNote(appointment.service_request_id);
          pNotes.sort(
            (a: CompositionType, b: CompositionType) =>
              moment(a.date_submitted).unix() - moment(b.date_submitted).unix()
          );
          setNotes(pNotes);
        } catch (e) {
          setError('An error occured while fetching the notes');
        }
      }

      setLoading(false);
    }
    fetchData();
  }, [appointment]);

  const onSubmit = async (values: any) => {
    try {
      const promises = [MedicalApi.setOGAppointmentAsCopied(appointment.id)];

      // if first appt (add to hsrm), update expiration date using the field value
      const hasToUpdateExpirationDate = toAdd && values?.expirationDate && serviceRequest?.id;
      if (hasToUpdateExpirationDate) {
        promises.push(
          MedicalApi.updateServiceRequest(serviceRequest?.id, {
            expiration_date: values.expirationDate,
          })
        );
      }

      // Mark combined soap notes and questionnaire responses as final
      promises.push(
        ...combinedSoapNoteIds.map((id) =>
          MedicalApi.updateComposition(id, {
            status: 'final',
          })
        )
      );
      promises.push(
        ...combinedQuestionnaireResponseIds.map((id) =>
          EvaluationApi.updateQuestionnaireResponse(id, {
            status: 'final',
          })
        )
      );

      await Promise.all(promises);
      openAlert({
        title: `Appointment Marked as Copied to HSRM${
          hasToUpdateExpirationDate ? `, and expiration date updated.` : ''
        }`,
      });
      onClose?.(true);
    } catch (e) {
      setError('An error occured while marking the appointment as copied to HSRM');
      scrollTop();
      console.error(e);
    }
  };

  const onSubmitError = async () => {
    form.setFormError('Please fix the errors below');
    scrollTop();
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    disabled: true,
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      style: { pointerEvents: 'none' },
      endAdornment: (
        <InputAdornment position='end'>
          <DuplicateCopyBoldIcon size={24} />
        </InputAdornment>
      ),
    },
  };

  const expirationFieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  const copy = (value = '') => {
    copyToClipboard(value);
  };

  const showExpiration = toAdd || (!appointment?.copied_into_hsrm && appointment?.first_service_request_appointment);

  const getDescription = (noteCount: number, evaluationCount: number) => {
    let description = 'Includes ';
    if (noteCount > 0) {
      description += `${noteCount} SOAP Note${noteCount > 1 ? 's' : ''}`;
    }

    if (noteCount > 0 && evaluationCount > 0) {
      description += ' and ';
    }

    if (evaluationCount > 0) {
      description += `${evaluationCount} Evaluation${evaluationCount > 1 ? 's' : ''}`;
    }

    return description;
  };

  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={error}
      title={`${toAdd ? 'Add' : 'Copy'} Appointment to HSRM`}
      description={
        <>
          Follow these steps to move the appointment details to HSRM.
          <br />
          Need help? Read the Process Overview.
          <p className={cn(styles.copyWrapper, styles.appointmentId)} onClick={() => copy(appointment.id)}>
            Appointment ID {appointment.id}
            <DuplicateCopyBoldIcon size={24} />
          </p>
        </>
      }
      className={styles.base}
      footerClassName={styles.footer}
      onSubmit={form.handleSubmit(onSubmit, onSubmitError)}
      actions={[
        { label: 'Back', action: 'close' },
        { label: 'Finish', action: 'submit' },
      ]}>
      <Grid className={styles.section} container spacing={3}>
        <Grid xs={12}>
          <p className={styles.title}>1. Find referral in HSRM portal</p>
        </Grid>
        <Grid xs={6} className={styles.copyWrapper} onClick={() => copy(serviceRequest?.referral_number)}>
          <TextField value={serviceRequest?.referral_number} label='Referral #' {...fieldProps} />
        </Grid>
      </Grid>
      <Grid className={styles.section} container spacing={3}>
        <Grid xs={12}>
          <p className={styles.title}>2. Add Appointment Date and Time in HSRM</p>
        </Grid>
        <Grid
          xs={6}
          className={styles.copyWrapper}
          onClick={() => copy(moment(appointment.date).tz('America/New_York').format('YYYY-MM-DD'))}>
          <TextField
            value={moment(appointment.date).tz('America/New_York').format('YYYY-MM-DD')}
            label='Appointment Date'
            type='date'
            {...fieldProps}
          />
        </Grid>
        <Grid
          xs={6}
          className={styles.copyWrapper}
          onClick={() => copy(moment(appointment.date).tz('America/New_York').format('HH:mm z'))}>
          <TextField
            value={moment(appointment.date).tz('America/New_York').format('HH:mm z')}
            label='Appointment Time (Military Time)'
            {...fieldProps}
          />
        </Grid>
        {appointment.provider && (
          <>
            <Grid xs={6} className={styles.copyWrapper} onClick={() => copy(appointment.provider?.npi)}>
              <TextField value={appointment.provider?.npi} label='Provider NPI' {...fieldProps} />
            </Grid>
            <Grid
              xs={6}
              className={styles.copyWrapper}
              onClick={() => copy(`${appointment.provider?.fname} ${appointment.provider?.lname}`)}>
              <TextField
                value={`${appointment.provider?.fname} ${appointment.provider?.lname}`}
                label='Provider Name'
                {...fieldProps}
              />
            </Grid>
          </>
        )}
      </Grid>
      {/*
        If appt goes from "addable" to "copyable" without being copied to hsrm first,
        we'll still show expiration update field on copy modal
      */}
      {showExpiration && (
        <Grid className={styles.section} container spacing={3}>
          <Grid xs={12}>
            <p className={styles.title}>3. Update referral’s expiration date</p>
          </Grid>
          <Grid xs={6}>
            <TextField
              {...bind('expirationDate', {
                required: true,
                validate: (val: any) => {
                  if (val && moment(val, 'YYYY-MM-DD').diff(moment()) < 0)
                    return 'Needs to be greater than the issue date';
                  return null;
                },
              })}
              label='New Expiration Date'
              type='date'
              {...expirationFieldProps}
            />
            <p className={styles.currentExpDateHelper}>
              Current expiration date is {moment(serviceRequest?.expiration_date).format('MM/DD/YYYY')}
            </p>
          </Grid>
        </Grid>
      )}
      {!toAdd && (
        <>
          {notes?.length > 0 && (
            <>
              <Grid className={styles.section} container spacing={3}>
                <Grid xs={12}>
                  <p className={styles.title}>{showExpiration ? 4 : 3}. Upload Files into HSRM appointment</p>
                </Grid>
                <Grid xs={12} className={cn(styles.files)}>
                  <FilesContainer
                    label='Files to Upload'
                    showTitle={false}
                    files={uploadedFiles?.map(({ file, soapNoteIds, appointmentIds, evaluationIds }) => ({
                      id: file.id,
                      url: file.location,
                      description: getDescription(soapNoteIds.length, appointmentIds.length + evaluationIds.length),
                      meta: {
                        fileName: file?.meta?.filename,
                        fileSize: file?.meta?.filesize,
                        mimeType: file?.meta?.mimetype,
                        tags: file?.meta?.tags,
                        created: file?.meta?.created_utc,
                      },
                    }))}
                    condensed
                  />
                  <Link
                    component='button'
                    variant='body2'
                    underline='always'
                    onClick={(e: any) => {
                      e.preventDefault();
                      openCombineFiles();
                    }}>
                    Choose Specific Files to Include
                  </Link>
                </Grid>
              </Grid>
            </>
          )}
          <Grid className={styles.section} container spacing={3}>
            <Grid xs={12}>
              <p className={styles.title}>
                {showExpiration && notes?.length > 0 ? 5 : 4}. Mark service as completed in HSRM
              </p>
            </Grid>
          </Grid>
        </>
      )}
    </Modal>
  );
}
